import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchUser } from '../services/userService';
import { UserContext } from '../context/UserContext';

const TokenRedirect: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const accessToken = queryParams.get('access_token') || '';
    const refreshToken = queryParams.get('refresh_token') || '';

    // Store tokens in localStorage
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    const checkUser = async () => {
      const user = await fetchUser();
      if (user) {
        userContext?.setUser(user);
        navigate('/');
      }
    };

    checkUser();
  }, [location, navigate]);

  return <div></div>;
};

export default TokenRedirect;
