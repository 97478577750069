import React from 'react';
import CountriesDropdown from '../common/CountriesDropdown';
import { IUser } from '../../interfaces/User';

interface UserProfileFormProps {
  user: IUser | null;
  values: {
    firstName: string;
    lastName: string;
    email: string;
    status: string;
    gender: string;
    country: string;
    city: string;
    mobileNumber: string;
    countryCode: string;
    address: string;
    postalCode: string;
    birthDate: string;
  };
  handleChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => void;
  countries: any[];
}

const UserProfileForm: React.FC<UserProfileFormProps> = ({
  user,
  values,
  handleChange,
  countries,
}) => {
  const onCountrySelect = (dialCode: string) => {
    handleChange({
      target: { name: 'countryCode', value: dialCode },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <>
      <div className="border md:w-3/12 py-[12px] px-[24px] rounded-3xl flex justify-center flex-col bg-[#F0F5FF]">
        <div className="flex flex-col">
          <img
            src="/assets/profilePicture.png"
            alt="profile-pic"
            className="h-[50px] w-[50px]"
          />
          <h2 className="font-header text-[20px] font-[500]">
            {user?.firstName ? `${user.firstName},` : ''}
          </h2>
        </div>
        <span className="text-sm leading-none mt-4 mb-5 text-[#4F4949] font-content">
          Complete Your Profile for a Personalized Experience!
        </span>
      </div>
      <div className="md:w-4/12 flex flex-col justify-between">
        <input
          type="text"
          placeholder="First Name"
          onChange={handleChange}
          name="firstName"
          value={values.firstName || ''}
          className="p-2 rounded-full border border-[#B3B3B2] w-full mb-4 outline-none"
        />
        <input
          type="text"
          placeholder="Last Name"
          onChange={handleChange}
          value={values.lastName || ''}
          name="lastName"
          className="p-2 rounded-full border border-[#B3B3B2] w-full mb-4 outline-none"
        />
        <select
          className="p-2 rounded-full border border-[#B3B3B2] w-full mb-4"
          value={values.gender || ''}
          name="gender"
          onChange={handleChange}
        >
          <option value="" disabled>
            Select Gender
          </option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
        <input
          type="text"
          placeholder="Email Address"
          value={values.email || ''}
          onChange={handleChange}
          name="email"
          className="p-2 rounded-full border border-[#B3B3B2] w-full mb-4 outline-none"
        />
        <input
          type="date"
          placeholder="Birth Date"
          value={values.birthDate || ''}
          onChange={handleChange}
          name="birthDate"
          className="p-2 rounded-full border border-[#B3B3B2] w-full mb-4 outline-none"
        />
        <div className="rounded-full border border-[#B3B3B2] w-full mb-4 outline-none flex">
          <div className="border h-full rounded-l-full bg-gray-100 p-1 w-1/4 flex justify-center items-center gap-3 border-r border-gray-300">
            <CountriesDropdown
              countries={countries}
              value={values.countryCode || ''}
              onCountrySelect={onCountrySelect}
            />
          </div>
          <input
            type="text"
            placeholder="Mobile Number"
            value={values.mobileNumber || ''}
            onChange={handleChange}
            name="mobileNumber"
            className="border w-full rounded-r-full px-2"
          />
        </div>
      </div>
      <div className="md:w-5/12 flex flex-col justify-between">
        <input
          type="text"
          placeholder="Enter Country"
          value={values.country || ''}
          onChange={handleChange}
          name="country"
          className="p-2 rounded-full border border-[#B3B3B2] w-full mb-4 outline-none"
        />
        <textarea
          placeholder="Enter Address"
          value={values.address || ''}
          onChange={handleChange}
          name="address"
          className="w-full h-[50px] md:h-full rounded-3xl border border-gray-400 outline-none resize-none p-3 mb-4"
        />
        <input
          type="text"
          placeholder="Postal Code"
          value={values.postalCode || ''}
          onChange={handleChange}
          name="postalCode"
          className="p-2 rounded-full border border-[#B3B3B2] w-full mb-4 outline-none"
        />
        <input
          type="text"
          placeholder="Enter City"
          value={values.city || ''}
          name="city"
          onChange={handleChange}
          className="p-2 rounded-full border border-[#B3B3B2] w-full mb-4 outline-none"
        />
      </div>
    </>
  );
};

export default UserProfileForm;
