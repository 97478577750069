import React, { useState } from 'react';
import EyeIcon from '../../assets/eye.svg';
import EyeSlashIcon from '../../assets/eye-slash.svg';

interface PasswordInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  name?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  value,
  onChange,
  placeholder = '',
  name = '',
  id = '',
  required = false,
  disabled = false,
  className = '',
}) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="relative w-full">
      <input
        type={isPasswordVisible ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        id={id}
        className={`${className} pr-10 box-border`} // Extra padding for the icon
        aria-label="Password"
        required={required}
        disabled={disabled}
      />
      <button
        type="button"
        onClick={togglePasswordVisibility}
        className="absolute inset-y-0 right-3 flex items-center text-gray-500 focus:outline-none"
        aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
        disabled={disabled}
        style={{ paddingRight: '8px' }}
      >
        <img
          src={isPasswordVisible ? EyeSlashIcon : EyeIcon}
          alt={isPasswordVisible ? 'Hide password' : 'Show password'}
          className="w-5 h-5"
        />
      </button>
    </div>
  );
};

export default PasswordInput;
