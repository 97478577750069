import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { capitalizeFirstLetter } from '../../utils/caseUtils';
import { getInfoText } from '../../utils/brandUtils';
import UpdatePasswordForm from '../../components/profile/UpdatePasswordForm';
import { UserPassword, UserUpdate } from '../../services/userService';
import { toast } from 'react-toastify';

interface UserPasswordValues {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const User: React.FC = () => {
  const userContext = useContext(UserContext);
  const [editPasswordMode, setEditPasswordMode] = useState(false);
  const [passValues, setPassValues] = useState<UserPasswordValues>({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    brandMemberRole: '',
  });
  const isPasswordMatch =
    passValues.newPassword === passValues.confirmNewPassword &&
    passValues.newPassword.length > 0 &&
    passValues.oldPassword.length > 0;

  if (!userContext) {
    throw new Error('useContext must be used within a UserProvider');
  }

  const { user } = userContext;

  useEffect(() => {
    if (user) {
      setFormData({
        email: user.email || '',
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        brandMemberRole: capitalizeFirstLetter(user.brandMemberRole || ''),
      });
    }
  }, [user]);

  const handleSave = async () => {
    if (!formData.email) {
      toast.error('Email is required');
      return;
    }
    if (user && user._id) {
      try {
        await UserUpdate(user._id, formData);
        toast.success('Profile updated successfully');
      } catch (error) {
        toast.error('Failed to update profile');
      }
    }
  };

  const handleUpdatePassword = async () => {
    if (isPasswordMatch) {
      if (user && user._id) {
        try {
          await UserPassword(user._id, {
            oldPassword: passValues.oldPassword,
            newPassword: passValues.newPassword,
          });
          toast.success('Password updated successfully');
          setPassValues({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          });
          setEditPasswordMode(false);
        } catch (error) {
          toast.error('Failed to update password');
        }
      }
    } else {
      toast.error('New passwords do not match');
    }
  };

  const handleCancel = () => {
    if (user) {
      setFormData({
        email: user.email || '',
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        brandMemberRole: capitalizeFirstLetter(user.brandMemberRole || ''),
      });
      setPassValues({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });
    }
  };

  const handlePassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setPassValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="h-full">
      <div className="flex flex-col md:flex-row items-center">
        <div className="bg-[#F0F5FF] md:w-2/4 rounded-3xl flex flex-row items-center p-4">
          <div className="p-1">
            <div className="w-[80px] h-[80px] rounded-full">
              <img
                src="/assets/profilePicture.png"
                alt="profile-pic"
                className="h-full w-full"
              />
            </div>
          </div>
          <div className="px-2">
            <h1 className="text-[24px] font-header mb-1">
              {formData.brandMemberRole}
            </h1>
            <p className="leading-none">
              {getInfoText(formData.brandMemberRole)}
            </p>
          </div>
        </div>
        <div className="md:w-2/4 mt-5 md:mt-0 md:pl-6 w-full">
          <input
            type="text"
            placeholder="Email Address"
            value={formData.email}
            name="email"
            onChange={handleChange}
            className="p-3 rounded-full border border-[#B3B3B2] w-full mb-4 outline-none"
          />
          <input
            type="text"
            value={formData.firstName}
            name="firstName"
            placeholder="First Name"
            onChange={handleChange}
            className="p-3 rounded-full border border-[#B3B3B2] w-full mb-4 outline-none"
          />
          <input
            type="text"
            value={formData.lastName}
            name="lastName"
            placeholder="Last Name"
            onChange={handleChange}
            className="p-3 rounded-full border border-[#B3B3B2] w-full outline-none"
          />
        </div>
      </div>
      <div className="flex mt-6 justify-end gap-4">
        <button
          className="md:mt-0 md:ml-2 ml-0 border-[1px] w-[120px] font-bold bg-[#F1F5F9] rounded-full px-[20px] py-[8px] text-[#113E53] font-header"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className="md:mt-0 md:ml-2 ml-0 border-[1px] w-[120px] border-[#113E53] font-bold bg-[#113E53] rounded-full px-[20px] py-[8px] text-white font-header"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
      <div className="flex flex-col mt-6 gap-4">
        <h1 className="text-4 font-[600] text-[#172B4D]">Password Set-up</h1>
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <UpdatePasswordForm
            passValues={passValues}
            handlePassChange={handlePassChange}
            editPasswordMode={editPasswordMode}
          />
        </div>
      </div>
      <div className="flex justify-end gap-4">
        {!editPasswordMode ? (
          <button
            onClick={() => setEditPasswordMode(true)}
            className="md:mt-0 md:ml-2 ml-0 border-[1px] w-[120px] border-[#113E53] font-bold bg-[#113E53] rounded-full px-[20px] py-[8px] text-white font-header"
          >
            Edit
          </button>
        ) : (
          <>
            <button
              onClick={() => {
                setEditPasswordMode(false);
                handleCancel();
              }}
              className="md:mt-0 md:ml-2 ml-0 border-[1px] w-[120px] font-bold bg-[#F1F5F9] rounded-full px-[20px] py-[8px] text-[#113E53] font-header"
            >
              Cancel
            </button>
            <button
              onClick={handleUpdatePassword}
              disabled={!isPasswordMatch}
              className={`md:mt-0 md:ml-2 ml-0 border-[1px] w-[120px] font-bold ${
                isPasswordMatch
                  ? 'bg-[#113E53] text-white'
                  : 'bg-gray-300 text-gray-500'
              } rounded-full px-[20px] py-[8px]`}
            >
              Save
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default User;
