import { useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { getBrand, updateBrand } from '../../services/userService';
import { IBrand } from '../../interfaces/Brand';
import CountriesDropdown from '../../components/common/CountriesDropdown';
import { getCountriesData } from '../../utils/countryUtils';
import { toast } from 'react-toastify';
import { BrandMemberRole } from '../../types/enums';

const Brand: React.FC = () => {
  const userContext = useContext(UserContext);
  const [brand, setBrand] = useState<IBrand | null>(null);

  if (!userContext) {
    throw new Error('useContext must be used within a UserProvider');
  }

  const { user } = userContext;
  const isAdmin = useMemo(
    () => user?.brandMemberRole === BrandMemberRole.Admin,
    [user?.brandMemberRole],
  );
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    countryCode: '',
    country: '',
    city: '',
    postalCode: '',
    address: '',
  });
  const [countries, setCountries] = useState<any[]>([]);

  const fetchBrand = async () => {
    try {
      if (!user) return;
      const response = await getBrand(user._id, user.brand ?? '');
      const brandData = response;
      setBrand(brandData);
      setFormValues({
        name: brandData.name || '',
        email: brandData.email,
        mobileNumber: brandData.mobileNumber || '',
        countryCode: brandData.countryCode || '',
        country: brandData.country || '',
        city: brandData.city || '',
        postalCode: brandData.postalCode || '',
        address: brandData.address || '',
      });
    } catch (error) {
      console.error('Error when fetching brand', error);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    if (!user || !user.brand) return;

    if (!formValues.name || !formValues.email) {
      toast.error('Brand Name and Email are required');
      return;
    }

    try {
      const brandData = await updateBrand(user._id, user.brand, formValues);
      toast.success('Brand updated successfully');
      setFormValues({
        name: brandData.name || '',
        email: brandData.email,
        mobileNumber: brandData.mobileNumber || '',
        countryCode: brandData.countryCode || '',
        country: brandData.country || '',
        city: brandData.city || '',
        postalCode: brandData.postalCode || '',
        address: brandData.address || '',
      });
    } catch (error) {
      toast.error('Error when updating brand' + error);
    }
  };

  const onCountrySelect = (dialCode: string) => {
    handleChange({
      target: { name: 'countryCode', value: dialCode },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  useEffect(() => {
    fetchBrand();
    setCountries(getCountriesData());
  }, [user]);

  return (
    <div>
      <div className="gap-4 flex flex-col md:flex-row md:h-[250px] relative">
        <div className="border md:w-3/12 py-[12px] px-[24px] rounded-3xl flex justify-end flex-col bg-[#F0F5FF]">
          <div className="flex flex-col">
            <h2 className="font-header text-[20px] font-[500]">
              {brand?.name || 'FLYBIRD'}
            </h2>
          </div>
          <span className="text-sm leading-none mt-4 mb-5 text-[#4F4949] font-content">
            Complete Your Profile for a Personalized Experience!
          </span>
        </div>

        <div className="md:w-4/12 flex flex-col justify-between">
          <input
            type="text"
            name="name"
            placeholder="Brand Name"
            value={formValues.name}
            onChange={handleChange}
            disabled={!isAdmin}
            className="p-3 rounded-full border border-[#B3B3B2] w-full mb-4 outline-none"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={formValues.email}
            onChange={handleChange}
            disabled={!isAdmin}
            className="p-3 rounded-full border border-[#B3B3B2] w-full mb-4 outline-none"
            required
          />
          <div className="rounded-full border border-[#B3B3B2] w-full mb-4 outline-none flex">
            <div className="border h-full rounded-l-full bg-gray-100 p-1 w-1/4 flex justify-center items-center gap-3 border-r border-gray-300">
              <CountriesDropdown
                countries={countries}
                value={formValues.countryCode || ''}
                onCountrySelect={onCountrySelect}
                disabled={!isAdmin}
              />
            </div>
            <input
              type="text"
              placeholder="Mobile Number"
              value={formValues.mobileNumber || ''}
              onChange={handleChange}
              name="mobileNumber"
              disabled={!isAdmin}
              className="border w-full rounded-r-full px-2"
            />
          </div>

          <input
            type="text"
            name="country"
            placeholder="Country"
            value={formValues.country}
            onChange={handleChange}
            disabled={!isAdmin}
            className="p-3 rounded-full border border-[#B3B3B2] w-full mb-4 outline-none"
          />
        </div>

        <div className="md:w-5/12 flex flex-col justify-between">
          <input
            type="text"
            name="postalCode"
            placeholder="Postal Code"
            value={formValues.postalCode}
            onChange={handleChange}
            disabled={!isAdmin}
            className="p-3 rounded-full border border-[#B3B3B2] w-full mb-4 outline-none"
          />
          <input
            type="text"
            name="city"
            placeholder="City"
            value={formValues.city}
            onChange={handleChange}
            disabled={!isAdmin}
            className="p-3 rounded-full border border-[#B3B3B2] w-full mb-4 outline-none"
          />
          <textarea
            name="address"
            placeholder="Enter Address"
            value={formValues.address}
            onChange={handleChange}
            disabled={!isAdmin}
            className="w-full h-[50px] md:h-full rounded-3xl border border-gray-400 outline-none resize-none p-3"
            cols={500}
          />
        </div>
      </div>

      <div className="flex mt-6 justify-end gap-4">
        <button
          className="md:mt-0 md:ml-2 ml-0 border-[1px] w-[120px] font-bold bg-[#F1F5F9] rounded-full px-[20px] py-[8px] text-[#113E53] font-header"
          onClick={fetchBrand}
          disabled={!isAdmin}
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          disabled={!isAdmin}
          className="md:mt-0 md:ml-2 ml-0 border-[1px] w-[120px] border-[#113E53] font-bold bg-[#113E53] rounded-full px-[20px] py-[8px] text-white font-header"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Brand;
