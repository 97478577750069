import React from 'react';

interface CustomerDetailsFormProps {
  customerValues: any;
  handleCustomerChange: (values: any) => void;
}

const CustomerDetailsForm: React.FC<CustomerDetailsFormProps> = ({
  customerValues,
  handleCustomerChange,
}) => {
  return (
    <div>
      <h1 className="font-semibold text-lg font-content mb-4">
        Customer Profile
      </h1>
      <div className="space-y-4">
        {/* Flex container to manage responsive layout */}
        <div className="flex flex-col md:flex-row gap-4">
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={customerValues.firstName}
            onChange={handleCustomerChange}
            className="w-full md:w-1/2 rounded-3xl p-2 outline-none border"
          />
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={customerValues.lastName}
            onChange={handleCustomerChange}
            className="w-full md:w-1/2 rounded-3xl p-2 outline-none border"
          />
        </div>

        <div className="flex flex-col md:flex-row gap-4">
          <input
            type="text"
            placeholder="Company Name"
            name="companyName"
            value={customerValues.companyName}
            onChange={handleCustomerChange}
            className="w-full md:w-1/2 rounded-3xl p-2 outline-none border"
          />
          <input
            type="text"
            placeholder="Postal Code / ZIP"
            name="postalCode"
            value={customerValues.postalCode}
            onChange={handleCustomerChange}
            className="w-full md:w-1/2 rounded-3xl p-2 outline-none border"
          />
        </div>

        <input
          type="text"
          placeholder="Address"
          name="address"
          value={customerValues.address}
          onChange={handleCustomerChange}
          className="w-full rounded-3xl p-2 outline-none border"
        />

        <div className="flex flex-col md:flex-row gap-4">
          <input
            type="text"
            placeholder="Town / City"
            name="city"
            value={customerValues.city}
            onChange={handleCustomerChange}
            className="w-full md:w-1/2 rounded-3xl p-2 outline-none border"
          />
          <input
            type="text"
            placeholder="Country"
            name="country"
            value={customerValues.country}
            onChange={handleCustomerChange}
            className="w-full md:w-1/2 rounded-3xl p-2 outline-none border"
          />
        </div>

        <div className="flex flex-col md:flex-row gap-4">
          <input
            type="text"
            placeholder="Phone"
            name="phone"
            value={customerValues.phone}
            onChange={handleCustomerChange}
            className="w-full md:w-1/2 rounded-3xl p-2 outline-none border"
          />
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={customerValues.email}
            onChange={handleCustomerChange}
            className="w-full md:w-1/2 rounded-3xl p-2 outline-none border"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerDetailsForm;
