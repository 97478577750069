import React from 'react';
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from '../../svg';
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

interface props {
  link: string;
}

const SocialIcons: React.FC<props> = ({ link }) => {
  const instagramDirectLink = `https://www.instagram.com/direct/new/?text=${process.env.REACT_APP_WEBSITE_DOMAIN}/${link}`;
  const socialIconsList = [
    {
      icon: (
        <FacebookShareButton
          url={`${process.env.REACT_APP_WEBSITE_DOMAIN}/${link}`}
          title={`please checkout my profile`}
        >
          <FacebookIcon
            className="size-6 md:size-8 text-[#113E53]"
            onClick={undefined}
          />
        </FacebookShareButton>
      ),
      label: 'facebook',
    },
    {
      icon: (
        <a href={instagramDirectLink} target="_blank" rel="noopener noreferrer">
          <InstagramIcon
            onClick={undefined}
            className="size-6 md:size-8 text-[#113E53]"
          />
        </a>
      ),
      label: 'Instagram',
    },
    {
      icon: (
        <TwitterShareButton
          title="Above is my link to the post"
          url={`${process.env.REACT_APP_WEBSITE_DOMAIN}/${link}`}
        >
          <TwitterIcon className={'size-6 md:size-8'} onClick={undefined} />
        </TwitterShareButton>
      ),
      label: 'Twitter',
    },
    {
      icon: (
        <WhatsappShareButton
          url={`${process.env.REACT_APP_WEBSITE_DOMAIN}/${link}`}
        >
          <WhatsappIcon
            className="size-6 md:size-8 text-[#113E53]"
            onClick={undefined}
          />
        </WhatsappShareButton>
      ),
      label: 'Whatsapp',
    },
    {
      icon: (
        <EmailShareButton
          url={`${process.env.REACT_APP_WEBSITE_DOMAIN}/${link}`}
          title="this is my link"
        >
          <EmailIcon
            className="size-6 md:size-8 text-[#113E53]"
            onClick={undefined}
          />
        </EmailShareButton>
      ),

      label: 'E-mail',
    },
  ];
  return (
    <div className="flex justify-between items-center md:items-end gap-6 mt-8 mb-8 md:mb-16">
      {socialIconsList.map((val, index) => (
        <div key={index} className="text-center">
          <div className="md:p-6 rounded-full bg-[#9D9D9D0A] md:mb-2">
            {val.icon}
          </div>
          <span className="text-[#113E53] hidden md:block">{val.label}</span>
        </div>
      ))}
    </div>
  );
};

export default SocialIcons;
