import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { feature } from 'topojson-client';
import { Topology } from 'topojson-specification';
import { FeatureCollection, Geometry } from 'geojson';
import { CountryClicks } from '../../../types/interfaces';

interface WorldMapProps {
  countries: CountryClicks[];
}

const WorldMap: React.FC<WorldMapProps> = ({ countries }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const getClicksByCountryName = (iso: string): number => {
    const countryData = countries.find((c) => c.country === iso);
    return countryData ? countryData.clicks : 0;
  };

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3
      .select(svgRef.current)
      .attr('width', '100%')
      .attr('height', '100%');
    const svgNode = svg.node();
    if (!svgNode) return;
    const { width, height } = svgNode.getBoundingClientRect();

    const maxClicks = d3.max(countries, (d) => d.clicks) || 5000;

    const getLegendValues = (max: number): number[] => {
      const roundToNearestTen = (value: number) => Math.round(value / 10) * 10;

      return [
        0,
        roundToNearestTen(max * 0.25),
        roundToNearestTen(max * 0.5),
        roundToNearestTen(max * 0.75),
        roundToNearestTen(max),
      ];
    };

    const legendValues = getLegendValues(maxClicks);
    const colorScale = d3
      .scaleThreshold<number, string>()
      .domain(legendValues.slice(1))
      .range(['#E0C8FC', '#C39EFF', '#9C33ED', '#7A00E5', '#5e00c9']);
    const projection = d3
      .geoMercator()
      .scale(width / 5)
      .translate([width / 2, height / 2.2]);
    const path = d3.geoPath().projection(projection);

    d3.json('/data/countries-110m.json').then((data) => {
      if (!data) {
        console.error('Les données TopoJSON sont manquantes ou mal formatées.');
        return;
      }
      const worldData = data as Topology;
      if (!worldData.objects || !worldData.objects.ne_10m_admin_0_countries) {
        console.error(
          'Les données TopoJSON ne contiennent pas les objets attendus.',
        );
        return;
      }
      const countriesGeoJson = feature(
        worldData,
        worldData.objects.ne_10m_admin_0_countries,
      ) as FeatureCollection<Geometry>;

      svg.selectAll('.country').remove();
      svg
        .selectAll('.country')
        .data(countriesGeoJson.features)
        .join('path')
        .attr('class', 'country')
        .attr('d', path as any)
        .attr('fill', (d) => {
          const countryCode = (d.properties as any).ISO_A2_EH;
          const clicks = getClicksByCountryName(countryCode);
          return clicks > 0 ? colorScale(clicks) : '#f0f0f0';
        })
        .attr('stroke', '#ffffff')
        .attr('stroke-width', 0.5)
        .on('mouseover', (event, d) => {
          const countryName = (d.properties as any).NAME_EN;
          const countryCode = (d.properties as any).ISO_A2_EH;
          const clicks = getClicksByCountryName(countryCode);
          d3.select(tooltipRef.current)
            .style('opacity', 1)
            .html(`${countryName}: ${clicks} clicks`)
            .style('left', `${event.pageX + 10}px`)
            .style('top', `${event.pageY + 10}px`);
        })
        .on('mouseout', () => {
          d3.select(tooltipRef.current).style('opacity', 0);
        });

      svg.selectAll('.legend').remove();
      const legend = svg
        .append('g')
        .attr('class', 'legend')
        .attr(
          'transform',
          `translate(${width / 2 - legendValues.length * 20}, ${height - 60})`,
        ); // Centering the legend

      const legendSpacing = 40; // Adjusted spacing

      legendValues.forEach((value, index) => {
        legend
          .append('circle')
          .attr('cx', index * legendSpacing)
          .attr('cy', 0)
          .attr('r', 8)
          .style('fill', colorScale(value));
        legend
          .append('text')
          .attr('x', index * legendSpacing)
          .attr('y', 20)
          .attr('text-anchor', 'middle')
          .style('font-size', '12px')
          .text(value.toFixed(0));
      });
    });
  }, [countries]);

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <div
        ref={tooltipRef}
        style={{
          position: 'absolute',
          background: 'rgba(0, 0, 0, 0.7)',
          color: '#fff',
          padding: '8px 12px',
          borderRadius: '5px',
          pointerEvents: 'none',
          opacity: 0,
          whiteSpace: 'nowrap',
        }}
      ></div>

      <svg
        ref={svgRef}
        width="100%"
        height="100%"
        preserveAspectRatio="xMidYMid meet"
      >
        <g className="map">{/* La carte sera dessinée ici */}</g>
      </svg>
    </div>
  );
};

export default WorldMap;
