export const formatISODateToDMY = (isoDate: string | null): string => {
  if (!isoDate) return ''; // Handle empty or null values
  return isoDate.split('T')[0];
};

export const formatToISODate = (date: string | null): string => {
  if (!date) return ''; // Handle empty or null values

  // Append the time and timezone to make it a full ISO date
  return `${date}`;
};
