import { BrandMemberRole } from '../types/enums';

export const getInfoText = (role: string): string => {
  switch (role.toLowerCase()) {
    case BrandMemberRole.User:
      return 'A user can only view and copy smartlinks but cannot create, edit, or delete them. A user can also accept invitations from managers to join.and also see the analytics, all the users can see the balance of the clicks.';
    case BrandMemberRole.Manager:
      return 'A manager can create and edit smartlinks, but cannot delete them. A manager can also view analytics and invite users to join, but cannot buy clicks.';
    case BrandMemberRole.Admin:
      return 'An admin can perform all the actions that the account owner can do, such as creating and deleting smartlinks, adding and removing team members, changing account settings, and viewing analytics, buying the clicks.';
    default:
      return '';
  }
};
