import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, fetchUser } from '../services/userService';
import Logo from '../components/common/Logo';
import { LeftArrow } from './../svg';
import GoogleLogo from '../assets/google_logo.png';
import FacebookLogo from '../assets/facebook_logo.png';
import PasswordInput from '../components/common/PasswordInput';
import { toast } from 'react-toastify';

const Login: React.FC = () => {
  const [values, setValues] = useState({ email: '', password: '' });
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      try {
        const user = await fetchUser();
        if (user) navigate('/');
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
    checkUser();
  }, [navigate]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    if (!values.email || !values.password) {
      toast.error('Please enter both email and password.');
      return;
    }

    try {
      await login(values.email, values.password);
      navigate('/');
    } catch (error) {
      toast.error('Login failed. Please check your credentials and try again.');
      console.error('Login error:', error);
    }
  };

  const handleSocialSignin = (provider: 'google' | 'facebook') => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/${provider}`;
  };

  return (
    <div>
      <Logo />
      <div
        className="bg-white flex items-center justify-center"
        style={{ height: 'calc(100vh - 92px)' }}
      >
        <div className="w-full max-w-md p-4 md:p-8 bg-white rounded-lg relative">
          <div className="flex items-center md:space-x-4 mb-4 relative md:right-4">
            <button
              aria-label="Go back to website"
              onClick={() =>
                navigate(process.env.REACT_APP_WEBSITE_DOMAIN || '/')
              }
              className="text-lg relative bottom-3 hidden md:flex"
            >
              <LeftArrow className="size-10 border p-2 rounded-full border-[#113E53] bg-[#59FF93] hover:rotate-45 duration-150" />
            </button>
            <div>
              <h1 className="text-4xl font-semibold text-navy">Log In</h1>
              <p className="text-xl mt-2 text-gray-600">
                Welcome back! Log in to access your account..
              </p>
            </div>
          </div>

          <form className="space-y-4" autoComplete="off" onSubmit={handleLogin}>
            <input
              type="email"
              placeholder="Enter Your Email"
              value={values.email}
              onChange={handleChange}
              name="email"
              id="email"
              className="p-3 rounded-full border border-gray-500 w-full focus:outline-none focus:ring-2"
              aria-label="Email"
              required
            />
            <PasswordInput
              placeholder="Enter Your Password"
              value={values.password}
              onChange={handleChange}
              name="password"
              id="password"
              className="p-3 rounded-full border border-gray-500 w-full focus:outline-none focus:ring-2"
              aria-label="Password"
              required={true}
            />

            <div className="flex items-center justify-between">
              <label className="flex items-center text-gray-600">
                <input
                  type="checkbox"
                  className="mr-2 scale-150"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                  aria-label="Remember Me"
                />
                Remember Me
              </label>
              <button
                type="button"
                onClick={() => navigate('/forget-password')}
                className="text-navy hover:underline cursor-pointer text-[16px] font-[400]"
              >
                Forgot Password?
              </button>
            </div>

            <button
              type="submit"
              className={`w-full p-3 rounded-full ${
                values.email && values.password
                  ? 'bg-[#020D3A] text-white'
                  : 'bg-slate-500 text-white'
              } cursor-pointer`}
              disabled={!values.email || !values.password}
            >
              Log In
            </button>
          </form>

          <div className="flex items-center my-4">
            <div className="flex-grow border-t border-gray-300"></div>
            <span className="px-4 text-gray-600">OR</span>
            <div className="flex-grow border-t border-gray-300"></div>
          </div>

          <div className="flex flex-col md:flex-row items-center justify-center gap-4">
            <button
              className="flex w-full gap-2 justify-center py-3 px-8 bg-white border border-[#113E53] rounded-full shadow-sm hover:shadow-md"
              onClick={() => handleSocialSignin('google')}
            >
              <img src={GoogleLogo} alt="Google" width={20} height={20} />
              <span className="font-bold">Google</span>
            </button>
            <button
              className="flex w-full justify-center gap-2 py-3 px-8 bg-white border border-[#113E53] rounded-full shadow-sm hover:shadow-md"
              onClick={() => handleSocialSignin('facebook')}
            >
              <img src={FacebookLogo} alt="Facebook" width={20} height={20} />
              <span className="font-bold">Facebook</span>
            </button>
          </div>

          <p className="text-center text-gray-600 mt-6">
            Don’t have an account?{' '}
            <button
              onClick={() => navigate('/signup')}
              className="text-navy hover:underline cursor-pointer text-[16px] font-[400]"
            >
              Sign Up
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
