import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../components/common/Logo';
import GoogleLogo from '../assets/google_logo.png';
import FacebookLogo from '../assets/facebook_logo.png';
import Tick from '../assets/fi_tick.png';
import { LeftArrow } from './../svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import PasswordInput from '../components/common/PasswordInput';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post(`${apiUrl}/auth/sendResetOtp`, { email });
      setStep(2);
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  const handleOtpSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post(`${apiUrl}/auth/verifyResetOtp`, { email, token: otp });
      setStep(3);
    } catch (error) {
      toast.error('Invalid OTP. Please try again.');
    }
  };

  const handlePasswordReset = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.put(`${apiUrl}/auth/updatePassword`, {
        email,
        password: newPassword,
        token: otp,
      });
      setStep(4);
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  const handleLoginRedirect = () => {
    if (step === 1) navigate('/signin');
    if (step === 2) setStep(1);
    if (step === 3) setStep(2);
  };

  return (
    <div>
      <Logo />
      <div className="flex items-center justify-center min-h-screen bg-white">
        <div className="w-full max-w-md p-4 md:p-8 rounded-lg relative">
          <div
            className={`flex items-center space-x-4 mb-4 relative ${step === 3 ? 'right-0' : 'md:right-12'}`}
          >
            {(step === 1 || step === 2) && (
              <LeftArrow
                className="size-10 border p-2 rounded-full border-[#113E53] bg-[#59FF93] hover:rotate-45 duration-150"
                onClick={handleLoginRedirect}
              />
            )}
            <h1
              className={`text-4xl text-navy ${step === 4 ? 'hidden' : 'block'}`}
            >
              {step === 1
                ? 'Reset Password'
                : step === 2
                  ? 'OTP Verification'
                  : 'New Password'}
            </h1>
          </div>

          {step === 1 && (
            <form onSubmit={handleEmailSubmit}>
              <p className="text-xl relative left-4 ">
                Connect to the potential <br />
                influencers
              </p>
              <input
                type="email"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="p-3 rounded-full border border-gray-500 m-2 w-full focus:outline-none focus:ring-2"
              />
              <button
                type="submit"
                className="w-full p-3 m-2 rounded-full bg-[#020D3A] text-white focus:outline-none"
              >
                Send OTP
              </button>
            </form>
          )}

          {step === 2 && (
            <form onSubmit={handleOtpSubmit}>
              <p className="text-xl relative left-4 ">
                Connect to the potential <br />
                influencers
              </p>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="p-3 rounded-full border border-gray-500 m-2 w-full focus:outline-none focus:ring-2"
              />
              <button
                type="submit"
                className="w-full p-3 m-2 rounded-full bg-[#020D3A] text-white focus:outline-none"
              >
                Verify OTP
              </button>
            </form>
          )}

          {step === 3 && (
            <form onSubmit={handlePasswordReset}>
              <p className="text-xl relative left-4 ">
                Connect to the potential <br />
                influencers
              </p>
              <PasswordInput
                placeholder="Enter New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="p-3 rounded-full border border-gray-500 m-2 w-full focus:outline-none focus:ring-2"
              />
              <PasswordInput
                placeholder="Confirm Password"
                value={verifyPassword}
                onChange={(e) => setVerifyPassword(e.target.value)}
                className="p-3 rounded-full border border-gray-500 m-2 w-full focus:outline-none focus:ring-2"
              />
              <span className="text-red-500 text-center">
                {newPassword !== verifyPassword && 'Passwords do not match'}
              </span>
              <button
                type="submit"
                className="w-full p-3 m-2 rounded-full bg-[#020D3A] text-white focus:outline-none"
                disabled={newPassword !== verifyPassword}
              >
                Submit
              </button>
            </form>
          )}

          {step === 4 && (
            <div className="text-center self-center flex flex-col justify-center items-center">
              <div className="w-[100px] h-[100px] flex justify-center items-center bg-gray-100 rounded-full mb-3 border border-black ">
                <img src={Tick} alt="tick" width={50} height={50} />
              </div>
              <p>Password Changed Successfully</p>
              <button
                onClick={() => navigate('/signin')}
                className="font-bold underline cursor-pointer"
              >
                Back to Log In
              </button>
            </div>
          )}

          {(step === 1 || step === 2) && (
            <div className="flex items-center my-4">
              <div className="flex-grow border-t border-gray-300"></div>
              <span className="px-4 text-gray-600">OR</span>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>
          )}

          {(step === 1 || step === 2) && (
            <div className="md:flex items-center justify-center md:space-x-2 mb-6 mt-4">
              <button className="flex justify-center w-full gap-2 py-3 px-8 bg-white border mb-2 md:mb-0 rounded-full shadow-sm hover:shadow-md">
                <img src={GoogleLogo} alt="Google" width={20} height={20} />
                <span className="font-bold">Google</span>
              </button>
              <button className="flex justify-center w-full gap-2 p-3 px-8 bg-white border rounded-full shadow-sm hover:shadow-md">
                <img src={FacebookLogo} alt="Facebook" width={20} height={20} />
                <span className="font-bold">Facebook</span>
              </button>
            </div>
          )}
          {(step === 1 || step === 2) && (
            <p className="text-center text-gray-500 mt-4">
              Already have an account?{' '}
              <button
                onClick={() => navigate('/signin')}
                className="text-navy hover:underline cursor-pointer text-[16px] font-[400] underline bg-transparent border-none p-0"
              >
                Log In
              </button>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
