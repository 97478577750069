import React, { useState } from 'react';
import { InfoIcon } from '../../svg';
import Model from '../common/models/Model';
import { BrandMemberRole } from '../../types/enums';
import { capitalizeFirstLetter } from '../../utils/caseUtils';
import { getInfoText } from '../../utils/brandUtils';

interface AddUserModalProps {
  isModalOpen: boolean;
  handleModal: () => void;
  onAddUser: (user: { name: string; email: string; role: string }) => void;
}

const AddUserModal: React.FC<AddUserModalProps> = ({
  isModalOpen,
  handleModal,
  onAddUser,
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onAddUser({ name, email, role });
    handleModal();
    setName('');
    setEmail('');
    setRole('');
  };

  const handleClose = () => {
    setName('');
    setEmail('');
    setRole('');
    handleModal();
  };

  return (
    <Model isOpen={isModalOpen} onClose={handleClose}>
      <div className="md:w-[400px]">
        <h1 className="text-[24px] font-header text-[#212121]">Add New User</h1>
        <input
          type="text"
          placeholder="Full Name"
          className="w-full p-3 rounded-full border border-gray-400 my-[16px] font-content"
          value={name}
          onChange={(e) => setName(e.target.value)} // Mise à jour de l'état
        />
        <input
          type="text"
          placeholder="Work Email"
          className="w-full p-3 rounded-full border border-gray-400 mb-[16px] font-content"
          value={email}
          onChange={(e) => setEmail(e.target.value)} // Mise à jour de l'état
        />
        <select
          className="w-full cursor-pointer p-3 rounded-full border outline-none border-gray-400 mb-[16px]"
          value={role}
          onChange={(e) => setRole(e.target.value)} // Mise à jour de l'état
        >
          <option value="" disabled>
            Select Role
          </option>
          <option value={BrandMemberRole.User}>User</option>
          <option value={BrandMemberRole.Manager}>Manager</option>
          <option value={BrandMemberRole.Admin}>Admin</option>
        </select>
        <div className="mt-2 flex justify-end items-center gap-2">
          <button
            onClick={() => handleClose()}
            className="border border-gray-800 font-bold rounded-full px-[20px] py-[8px] font-header"
          >
            Cancel
          </button>
          <button
            className="border-[1px] font-bold bg-[#020D3A] rounded-full px-[20px] py-[8px] text-white font-header"
            onClick={handleSubmit}
          >
            Create User
          </button>
        </div>
        {role && (
          <div className="p-[24px] bg-[#f0f5ff] rounded-3xl mt-4">
            <div className="flex items-start">
              <div className="mr-2">
                <InfoIcon
                  className="size-6 text-[#4F4949]"
                  onClick={() => console.log('info')}
                />
              </div>
              <div className="mt-[2px]">
                <h2 className="font-header text-lg font-[600] leading-none text-[#172b4d] mb-2">
                  {capitalizeFirstLetter(role)}
                </h2>
                <p className="font-content text-sm text-[#4F4949]">
                  {getInfoText(role)}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Model>
  );
};

export default AddUserModal;
