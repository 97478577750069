import React from 'react';
import mainLogo from '../../assets/Logo.svg';

const Logo = () => {
  const handleClick = (): void => {
    const websiteDomain = process.env.REACT_APP_WEBSITE_DOMAIN || '/';
    window.location.href = websiteDomain;
  };

  return (
    <div className="flex justify-between items-center w-[100%] h-[52px] md:h-[92px] px-[20px] md:px-[80px]">
      <div>
        <div className="cursor-pointer">
          <img
            src={mainLogo}
            alt="Logo"
            width={150}
            height={40}
            onClick={() => handleClick()}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Logo;
