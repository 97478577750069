import { useContext, useEffect, useState } from 'react';
import Table from '../components/common/table';
import { getOrders, OrderType } from '../services/linkService';
import { UserContext } from '../context/UserContext';
import { capitalizeFirstLetter } from '../utils/caseUtils';
import useDeviceDetect from '../helpers/screens';

const MyWallet: React.FC = () => {
  const { isMobile } = useDeviceDetect();
  const [orders, setOrders] = useState<OrderType[]>([]);

  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error('useContext must be used within a UserProvider');
  }

  const { user } = userContext;

  const columns = [
    {
      title: 'Payment Id',
      dataIndex: 'paymentId',
      key: 'paymentId',
      width: '300px',
      headerAlign: 'left',
      cellAlign: 'left',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '200px',
      headerAlign: 'left',
      cellAlign: 'left',
      render: (row: OrderType) =>
        capitalizeFirstLetter(row.title) || 'No Title',
    },
    {
      title: 'Purchase Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '200px',
      headerAlign: 'left',
      cellAlign: 'left',
      render: (row: OrderType) => new Date(row.createdAt).toDateString(),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '250px',
      render: (row: OrderType) => (
        <button
          className={`${
            row.status === 'pending'
              ? 'bg-gray-100'
              : row.status === 'succeeded'
                ? 'bg-green-100 text-green-500'
                : 'bg-red-100 text-red-500'
          } px-4 rounded-full`}
        >
          {row.status}
        </button>
      ),
      key: 'status',
      headerAlign: 'left',
      cellAlign: 'left',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: '150px',
      headerAlign: 'left',
      cellAlign: 'left',
      render: (row: OrderType) =>
        `$${(row.amount / 100).toFixed(2)} ${row.currency.toUpperCase()}`,
    },
  ];

  useEffect(() => {
    if (user && user._id) {
      getOrders(user._id).then((data) => {
        setOrders(data?.orders || []);
      });
    }
  }, [user]);

  return (
    <div className="bg-[#F0F5FF]">
      <div className="flex flex-col p-[12px] sm:p-[24px]">
        <h1 className="text-2xl font-header">Purchase History</h1>
      </div>
      <div className="bg-white p-[12px] sm:p-[24px]">
        {!isMobile ? (
          <Table columns={columns} dataSource={orders} tableData={orders} />
        ) : (
          orders.map((order, index) => (
            <div
              key={index}
              className="border-y border-b py-[12px] h-auto flex flex-col justify-between"
            >
              <table className="table-auto w-full">
                <tbody>
                  <tr>
                    <td className="font-semibold py-2 text-sm sm:text-[16px]">
                      Date
                    </td>
                    <td className="text-[#020D3A] text-sm sm:text-[16px]">
                      {new Date(order.createdAt).toDateString()}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-semibold py-2 text-sm sm:text-[16px]">
                      Amount
                    </td>
                    <td className="text-[#020D3A] text-sm sm:text-[16px]">
                      ${(order.amount / 100).toFixed(2)}{' '}
                      {order.currency.toUpperCase()}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-semibold py-2 text-sm sm:text-[16px]">
                      Payment Id
                    </td>
                    <td className="text-[#020D3A] text-sm sm:text-[16px]">
                      {order.paymentId}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-semibold py-2 pr-4 text-sm sm:text-[16px]">
                      Status
                    </td>
                    <td className="text-[#020D3A] text-sm sm:text-[16px]">
                      <span
                        className={`${
                          order.status === 'pending'
                            ? 'bg-gray-100'
                            : order.status === 'succeeded'
                              ? 'bg-green-100 text-green-500'
                              : 'bg-red-100 text-red-500'
                        } px-4 rounded-full  py-2`}
                      >
                        {order.status}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="font-semibold py-2 text-sm sm:text-[16px]">
                      Plan
                    </td>
                    <td className="text-[#020D3A] text-sm sm:text-[16px]">
                      {capitalizeFirstLetter(order.title) || 'No Title'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MyWallet;
