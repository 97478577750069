import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../components/common/Logo';
import { toast } from 'react-toastify';
import { activateBrandUser } from '../services/userService';
import PasswordInput from '../components/common/PasswordInput';

const ActivateAccount: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const { token } = useParams<{ token: string }>();
  const [password, setPassword] = React.useState('');
  const [verifyPassword, setVerifyPassword] = React.useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!password || !verifyPassword) {
      toast.error('Please enter your password');
      return;
    }
    if (password !== verifyPassword) {
      toast.error('Passwords do not match');
      return;
    }

    try {
      const response = await activateBrandUser(
        token as string,
        userId as string,
        password,
      );
      console.log(response);
      const { accessToken, refreshToken } = response;
      console.log(accessToken, refreshToken);

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      navigate('/');
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <div>
      <Logo />
      <div className="flex items-center justify-center min-h-screen bg-white">
        <div className="w-full max-w-md p-4 md:p-8 rounded-lg relative">
          <div
            className={`flex items-center space-x-4 mb-4 relative md:right-12`}
          >
            <h1 className={`text-4xl text-navy block`}>Set Password</h1>
          </div>

          <form onSubmit={handleSubmit}>
            <PasswordInput
              placeholder="Enter Your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="p-3 rounded-full border border-gray-500 m-2 w-full focus:outline-none focus:ring-2"
            />
            <PasswordInput
              placeholder="Enter Your Password"
              value={verifyPassword}
              onChange={(e) => setVerifyPassword(e.target.value)}
              className="p-3 rounded-full border border-gray-500 m-2 w-full focus:outline-none focus:ring-2"
            />
            <button
              type="submit"
              className="w-full p-3 m-2 rounded-full bg-[#020D3A] text-white focus:outline-none"
            >
              Activate
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ActivateAccount;
