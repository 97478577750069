import React from 'react';
import { LeftArrow, RightArrow } from '../../../svg';
import useDeviceDetect from '../../../helpers/screens';

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  limit: { start: number; end: number };
}

const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  limit,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const { isMobile } = useDeviceDetect();

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers: (number | string)[] = [];
    const maxPageNumbersToShow = 3;

    if (totalPages <= maxPageNumbersToShow + 2) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);

      const startPage = Math.max(currentPage - 1, 2);
      const endPage = Math.min(currentPage + 1, totalPages - 1);

      if (startPage > 2) {
        pageNumbers.push('...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }

      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((page, index) => (
      <span
        key={index}
        onClick={() => typeof page === 'number' && handlePageChange(page)}
        style={{
          border: page === currentPage ? '1px solid black' : '#f9f9f9',
        }}
        className="h-[25px] w-[40px] rounded-full cursor-pointer select-none flex justify-center items-center text-sm"
      >
        {page}
      </span>
    ));
  };

  return (
    <div className="flex justify-between items-center mt-4">
      {!isMobile ? (
        <span className="text-sm font-content text-gray-700">{`Showing ${limit.start} to ${limit.end} of ${totalItems} entries`}</span>
      ) : (
        <span></span>
      )}
      <div className="pagination flex items-center font-content">
        <LeftArrow
          className={`size-5 cursor-pointer ${
            currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={() => handlePageChange(currentPage - 1)}
        />
        <div className="flex mx-4">{renderPageNumbers()}</div>
        <RightArrow
          className={`size-5 cursor-pointer ${
            currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={() => handlePageChange(currentPage + 1)}
        />
      </div>
    </div>
  );
};

export default Pagination;
