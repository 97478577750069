import React, { useState } from 'react';
import Pagination from './UserTablePagination';
import { User, useUserColumns, useUsersData } from './TableData';
import LinkTableModal from './LinksTableModal';
import { SearchIcon } from '../../../svg';

const UsersTable: React.FC = () => {
  const [userFilter, setUserFilter] = useState('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [userPage, setUserPage] = useState(1); // Pagination for users
  const [sortField, setSortField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const entriesPerPage = 10;

  const {
    users,
    totalUsers,
    loading: usersLoading,
    error: usersError,
    totalPages,
  } = useUsersData(userFilter, userPage, entriesPerPage, sortField, sortOrder);

  // User table columns
  const userColumns = useUserColumns();

  // Handle sorting of the user table
  const handleSort = (field: string) => {
    const newSortOrder =
      sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  const handleUserSelect = (user: User) => {
    setSelectedUser(user);
    setShowModal(true); // Open the modal when a user is selected
  };

  const closeModal = () => {
    setShowModal(false); // Close the modal
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-xl font-bold mb-4">Users</h1>
      <div className="border-b-2 flex items-center w-full md:w-[300px] mb-4">
        <SearchIcon className={'size-5 text-gray-400'} />
        <input
          type="text"
          placeholder="Search"
          name="search"
          id="search"
          onChange={(e) => setUserFilter(e.target.value)}
          value={userFilter}
          className="border-none outline-none bg-transparent p-[10px]"
        />
      </div>

      {usersLoading ? (
        <p>Loading users...</p>
      ) : usersError ? (
        <p>{usersError}</p>
      ) : (
        <>
          <div className="overflow-x-auto rounded-2xl border border-gray-200 mt-1">
            <table className="min-w-full table-auto rounded-3xl">
              <thead>
                <tr className="bg-gray-100">
                  {userColumns.map((column) => (
                    <th
                      key={column.key}
                      className="font-medium px-4 py-2 cursor-pointer"
                      style={{
                        width: column.width,
                        textAlign:
                          column.headerAlign as React.CSSProperties['textAlign'],
                      }}
                      onClick={() => handleSort(column.key)}
                    >
                      {column.title}{' '}
                      {sortField === column.key &&
                        (sortOrder === 'asc' ? '↑' : '↓')}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300">
                {users
                  .slice()
                  .sort((a, b) => {
                    const aValue = (a as any)[sortField];
                    const bValue = (b as any)[sortField];
                    if (sortOrder === 'asc') {
                      return aValue > bValue ? 1 : -1;
                    } else {
                      return aValue < bValue ? 1 : -1;
                    }
                  })
                  .map((user) => (
                    <tr
                      key={user._id}
                      className="bg-white hover:bg-gray-50 cursor-pointer"
                      onClick={() => handleUserSelect(user)}
                    >
                      {userColumns.map((column) => (
                        <td
                          key={`${user._id}-${column.key}`} // Utilisation d'une clé unique
                          className="whitespace-nowrap overflow-hidden px-4 py-2 text-sm font-medium text-gray-900"
                          style={{
                            textAlign:
                              column.cellAlign as React.CSSProperties['textAlign'],
                            width: column.width,
                          }}
                        >
                          {(user as any)[column.dataIndex]}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <Pagination
            totalItems={totalUsers}
            itemsPerPage={entriesPerPage}
            currentPage={userPage}
            onPageChange={setUserPage}
            limit={{
              start: (userPage - 1) * entriesPerPage + 1,
              end: Math.min(
                userPage * entriesPerPage,
                totalPages * entriesPerPage,
              ),
            }}
          />
        </>
      )}

      {/* Modal to show the selected user's links */}
      {showModal && selectedUser && (
        <LinkTableModal links={selectedUser.links} handleClose={closeModal} />
      )}
    </div>
  );
};

export default UsersTable;
