import React, { useContext, useEffect, useState } from 'react';
import Header from '../components/common/MainContentHeader';
import InputWithIcon from '../components/link/CreateLinkInput';
import Button from '../components/link/CreateLinkButton';
import { UserContext } from '../context/UserContext';
import { getUserLinks, createLink } from '../services/linkService';
import BlankDashboard from '../components/dashboard/BlankDashboard';
import DefaultDashboard from '../components/dashboard/DefaultDashboard';
import AdminDashboard from '../components/dashboard/AdminDashboard';
import { BrandMemberRole, SortLinksByOptions } from '../types/enums';
import Loading from '../components/common/Loading';
import { toast } from 'react-toastify';

const Dashboard: React.FC = () => {
  const userContext = useContext(UserContext);
  const [userLinks, setUserLinks] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [newLink, setNewLink] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  if (!userContext) {
    throw new Error('useContext must be used within a UserProvider');
  }
  const { user } = userContext;

  const tabs = [
    { label: 'Newly Added', value: SortLinksByOptions.NewlyAdded },
    { label: 'Top Links', value: SortLinksByOptions.TopLinks },
    { label: 'Old Links', value: SortLinksByOptions.OldLinks },
  ];

  const fetchUserLinks = async () => {
    if (user && user._id) {
      try {
        const links = await getUserLinks(user._id, {
          sortBy: tabs[selectedTab].value,
          page: 1,
          limit: 3,
        });
        setUserLinks(links);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Failed to fetch user links:', error);
      }
    }
  };

  useEffect(() => {
    fetchUserLinks();
  }, [user, selectedTab]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewLink(event.target.value);
  };

  const handleCreateLink = async () => {
    const urlRegex = new RegExp(
      '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$',
    );
    if (!urlRegex.test(newLink)) {
      toast.error('Please enter a valid URL');
      return;
    }
    if (user && user._id && newLink) {
      try {
        await createLink(user._id, newLink);
        toast.success('Link created successfully!');
        setNewLink('');
        fetchUserLinks();
      } catch (error) {
        toast.error('Failed to create link');
      }
    }
  };

  const getTitle = () => {
    if (user && user.firstName) {
      return `${user.firstName}'s`;
    }
    return '';
  };

  const displayForBrandUser = () => {
    return (
      user !== undefined &&
      user !== null &&
      user.brandMemberRole !== BrandMemberRole.User
    );
  };

  return (
    <>
      {user && user.role === 'admin' ? (
        <AdminDashboard />
      ) : (
        <div className="bg-[#FCFCFC]">
          <div className="p-[24px] bg-[#F0F5FF] ">
            <Header title={getTitle()} subtitle="Dashboard" />
            {displayForBrandUser() && (
              <div className="mt-[24px] flex md:flex-row flex-col items-center">
                <InputWithIcon value={newLink} onChange={handleInputChange} />
                <Button text="Create Smart Link" onClick={handleCreateLink} />
              </div>
            )}
          </div>

          {isLoading ? (
            <Loading />
          ) : (
            <>
              {userLinks.length > 0 ? (
                <DefaultDashboard
                  links={userLinks}
                  tabs={tabs}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  refetchLinks={fetchUserLinks}
                />
              ) : (
                <BlankDashboard />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Dashboard;
