import React, { useEffect, useState, useCallback } from 'react';
import Dropdown from './Dropdown';
import { DropIcon } from '../../svg';

interface Country {
  name: string;
  iso2: string;
  dialCode: string;
  flag: string;
}

interface CountriesDropdownProps {
  countries: Country[];
  value?: string;
  onCountrySelect: (dialCode: string) => void;
  disabled?: boolean;
}

const CountriesDropdown: React.FC<CountriesDropdownProps> = ({
  countries,
  value: initialDialCode,
  onCountrySelect,
  disabled = false,
}) => {
  const [selectedDialCode, setSelectedDialCode] = useState<string | undefined>(
    initialDialCode,
  );
  const [selectedFlag, setSelectedFlag] = useState<string | null>(null);

  // Function to find country by dial code
  const findCountryByDialCode = useCallback(
    (dialCode: string) =>
      countries.find((country) => country.dialCode === dialCode),
    [countries],
  );

  // Function to set selected country by its dial code
  const setCountryByDialCode = useCallback(
    (dialCode: string) => {
      const country = findCountryByDialCode(dialCode);
      if (country) {
        setSelectedDialCode(country.dialCode);
        setSelectedFlag(country.flag);
      }
    },
    [findCountryByDialCode],
  );

  // Initialize dropdown with initial value if defined
  useEffect(() => {
    if (initialDialCode) {
      setCountryByDialCode(initialDialCode);
    }
  }, [initialDialCode, setCountryByDialCode]);

  const handleSelectCountry = (dialCode: string, flag: string) => {
    setSelectedDialCode(dialCode);
    setSelectedFlag(flag);
    onCountrySelect(dialCode);
  };

  return (
    <Dropdown
      side="left"
      disabled={disabled}
      label={
        <div
          className="flex items-center gap-2 cursor-pointer"
          aria-haspopup="listbox"
        >
          {!selectedFlag ? (
            <DropIcon
              className="text-gray-500 size-4 md:ml-8"
              onClick={undefined}
            />
          ) : (
            <>
              <img
                src={selectedFlag || 'logo192.png'}
                alt="selected flag"
                className="w-4 h-4 rounded-full"
              />
              <span className="rounded-l-full">{selectedDialCode}</span>
            </>
          )}
        </div>
      }
    >
      <ul
        role="listbox"
        aria-labelledby="country-selector"
        className="max-h-60 overflow-y-scroll bg-white rounded shadow-lg w-[250px]"
      >
        {countries.map((country) => (
          <li
            key={country.iso2}
            onClick={() => handleSelectCountry(country.dialCode, country.flag)}
            role="option"
            aria-selected={selectedDialCode === country.dialCode}
            className="flex items-center gap-2 cursor-pointer hover:bg-gray-200 px-2 py-1"
          >
            <img
              src={country.flag}
              alt={`${country.name} flag`}
              className="w-4 h-4 rounded-full"
            />
            <span className="whitespace-nowrap">{country.name}</span>
            <span>{`(${country.dialCode})`}</span>
          </li>
        ))}
      </ul>
    </Dropdown>
  );
};

CountriesDropdown.displayName = 'CountriesDropdown';

export default CountriesDropdown;
