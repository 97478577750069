import React from 'react';
import PasswordInput from '../common/PasswordInput';

interface UserPasswordValues {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface UpdatePasswordFormProps {
  passValues: UserPasswordValues;
  handlePassChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  editPasswordMode: boolean;
}

const UpdatePasswordForm: React.FC<UpdatePasswordFormProps> = ({
  passValues,
  handlePassChange,
  editPasswordMode,
}) => {
  return (
    <>
      <PasswordInput
        placeholder="Actual Password"
        value={passValues.oldPassword}
        name="oldPassword"
        onChange={handlePassChange}
        className="p-3 rounded-full border border-gray-500 w-full focus:outline-none focus:ring-2"
        disabled={!editPasswordMode}
      />
      <PasswordInput
        placeholder="New Password"
        value={passValues.newPassword}
        name="newPassword"
        onChange={handlePassChange}
        className="p-3 rounded-full border border-gray-500 w-full focus:outline-none focus:ring-2"
        disabled={!editPasswordMode}
      />
      <PasswordInput
        placeholder="Confirm New Password"
        value={passValues.confirmNewPassword}
        name="confirmNewPassword"
        onChange={handlePassChange}
        className="p-3 rounded-full border border-gray-500 w-full focus:outline-none focus:ring-2"
        disabled={!editPasswordMode}
      />
    </>
  );
};

export default UpdatePasswordForm;
