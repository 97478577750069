import { toast } from 'react-toastify';
import axiosInstance from '../config/axiosConfig';
import { IUser } from '../interfaces/User';
import { TimeInterval } from '../types/types';

export const login = async (email: string, password: string): Promise<any> => {
  try {
    const response = await axiosInstance.post('/auth/login', {
      email,
      password,
    });

    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('refreshToken', response.data.refreshToken);

    return response.data;
  } catch (error) {
    console.error('Error when logging in', error);
    toast.error('Login failed. Please check your credentials and try again.');
    return null;
  }
};

export const fetchUser = async (): Promise<any | null> => {
  try {
    const response = await axiosInstance.get<IUser>('/auth/me');

    return response.data;
  } catch (error) {
    console.error('Error when fetching user', error);
    return null;
  }
};

export const fetchRoleInBrand = async (
  user: number,
  brand: number,
): Promise<any | null> => {
  try {
    const response = await axiosInstance.get('/auth/me/brandRole', {
      params: { user, brand },
    });

    return response.data;
  } catch (error) {
    console.error('Error when fetching user', error);
    return null;
  }
};

export const fetchAllUsersAndLinks = async (params: any) => {
  try {
    const response = await axiosInstance.get('/users/fetchUsersWithLinks', {
      params,
    });

    return response.data;
  } catch (error) {
    console.error('Error when fetching users', error);
    return null;
  }
};

export const getAdminAnalytics = async () => {
  try {
    const response = await axiosInstance.get('/users/getAdminAnalytics');

    return response.data;
  } catch (error) {
    console.error('Error when fetching users', error);
    return null;
  }
};

export const UserProfile = async (userId: string) => {
  try {
    const response = await axiosInstance.get(`/users/${userId}`);

    return response.data;
  } catch (error) {
    console.error('Error when getting user', error);
    return null;
  }
};

export const ClickLeft = async (userId: string) => {
  try {
    const response = await axiosInstance.get(`/users/${userId}/clicks-left`);

    return response.data;
  } catch (error) {
    console.error('Error when fetching clicks left', error);
    return null;
  }
};

export const TotalClicks = async (userId: string, interval: TimeInterval) => {
  try {
    const response = await axiosInstance.get(`/users/${userId}/total-clicks`, {
      params: { interval },
    });

    return response.data;
  } catch (error) {
    console.error('Error when fetching clicks left', error);
    return null;
  }
};

export const TopPerformingSources = async (
  userId: string,
  interval: TimeInterval = 'year',
) => {
  try {
    const response = await axiosInstance.get(
      `/users/${userId}/top-5-best-performing-platforms`,
      {
        params: { interval },
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error when fetching top performing sources', error);
    return null;
  }
};

export const TopPerformingPlatform = async (
  userId: string,
  interval: TimeInterval,
) => {
  try {
    const response = await axiosInstance.get(
      `/users/${userId}/best-performing-platforms`,
      {
        params: { interval },
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error when fetching top performing sources', error);
    return null;
  }
};

export const TopCountries = async (userId: string, interval: TimeInterval) => {
  try {
    const response = await axiosInstance.get(`/users/${userId}/top-countries`, {
      params: { interval },
    });

    return response.data;
  } catch (error) {
    console.error('Error when fetching top country', error);
    return null;
  }
};

export const TopCities = async (userId: string, interval: TimeInterval) => {
  try {
    const response = await axiosInstance.get(`/users/${userId}/top-city`, {
      params: { interval },
    });

    return response.data;
  } catch (error) {
    console.error('Error when fetching top country', error);
    return null;
  }
};

export const AverageTimeToEngage = async (
  userId: string,
  interval: TimeInterval,
) => {
  try {
    const response = await axiosInstance.get(
      `/users/${userId}/top-average-time-to-engage`,
      {
        params: { interval },
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error when fetching average time to engage', error);
    return null;
  }
};

export const UserUpdate = async (userId: string, values: any) => {
  try {
    const response = await axiosInstance.put(`/users/${userId}`, values);

    return response.data;
  } catch (error) {
    console.error('Error when fetching user', error);
    return null;
  }
};

export const UserPassword = async (userId: string, values: any) => {
  try {
    await axiosInstance.put(`/users/${userId}/password`, values);
    return true;
  } catch (error) {
    console.error('Error when updating password', error);
    throw error;
  }
};

export const UserIntervalVisit = async (
  userId: string,
  interval: TimeInterval,
) => {
  try {
    const response = await axiosInstance.get(
      `/users/${userId}/clicks-by-user-interval`,
      {
        params: { interval },
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error when fetching user visits', error);
    return null;
  }
};

export const ProfileCompletion = async (userId: string) => {
  try {
    const response = await axiosInstance.get(
      `/users/${userId}/profile-completion`,
    );

    return response.data;
  } catch (error) {
    console.error('Error when fetching user profile completion', error);
    return null;
  }
};

export const addUserToBrand = async (
  userId: string,
  brandId: string,
  name: string,
  email: string,
  role: string,
) => {
  try {
    const response = await axiosInstance.post(
      `/users/${userId}/brand/${brandId}/invite`,
      {
        name,
        email,
        role,
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error when adding user to brand', error);
    return null;
  }
};

export const activateBrandUser = async (
  token: string,
  userId: string,
  password: string,
) => {
  try {
    const response = await axiosInstance.put(
      `/users/${userId}/brand/activate`,
      {
        token,
        password,
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error when activating user', error);
    return null;
  }
};

export const getBrandUsers = async (userId: string, brandId: string) => {
  try {
    const response = await axiosInstance.get(
      `/users/${userId}/brand/${brandId}/users`,
    );

    return response.data;
  } catch (error) {
    console.error('Error when fetching brand users', error);
    return null;
  }
};

export const getBrand = async (userId: string, brandId: string) => {
  try {
    const response = await axiosInstance.get(
      `/users/${userId}/brand/${brandId}`,
    );

    return response.data;
  } catch (error) {
    console.error('Error when fetching brand users', error);
    return null;
  }
};

export const updateBrand = async (
  userId: string,
  brandId: string,
  params: any,
) => {
  try {
    const response = await axiosInstance.put(
      `/users/${userId}/brand/${brandId}`,
      params,
    );

    return response.data;
  } catch (error) {
    console.error('Error when fetching brand users', error);
    return null;
  }
};
