import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const SocialAuth: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('access_token') && searchParams.get('refresh_token')) {
      localStorage.setItem(
        'accessToken',
        searchParams.get('access_token') || '',
      );
      localStorage.setItem(
        'refreshToken',
        searchParams.get('refresh_token') || '',
      );
    }

    navigate('/');
  }, [searchParams]);

  return <div />;
};

export default SocialAuth;
