import { useEffect, useMemo, useState } from 'react';
import { fetchAllUsersAndLinks } from '../../../services/userService';
import { getUserLinks } from '../../../services/linkService';

export interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  clicks: number;
  role: string;
  brand: string;
  createdAt: string;
  links: Link[];
  totalLinks?: number; // Ajout du champ totalLinks
  totalClicks?: number; // Ajout du champ totalClicks
}

export interface Link {
  originalUrl: string;
  shortUrl: string;
  targetSite: string;
  clickCount: number;
}

export const useUserColumns = () => {
  return useMemo(
    () => [
      {
        key: 'totalClicks',
        title: 'Total Clicks',
        dataIndex: 'totalClicks',
        width: '10%',
        headerAlign: 'center',
        cellAlign: 'center',
      },
      {
        key: 'totalLinks',
        title: 'Total Links',
        dataIndex: 'totalLinks',
        width: '10%',
        headerAlign: 'center',
        cellAlign: 'center',
      },
      {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
        width: '15%',
        headerAlign: 'left',
        cellAlign: 'left',
      },
      {
        key: 'firstName',
        title: 'First Name',
        dataIndex: 'firstName',
        width: '10%',
        headerAlign: 'left',
        cellAlign: 'left',
      },
      {
        key: 'lastName',
        title: 'Last Name',
        dataIndex: 'lastName',
        width: '10%',
        headerAlign: 'left',
        cellAlign: 'left',
      },
      {
        key: 'country',
        title: 'Country',
        dataIndex: 'country',
        width: '10%',
        headerAlign: 'center',
        cellAlign: 'center',
      },
      {
        key: 'city',
        title: 'City',
        dataIndex: 'city',
        width: '10%',
        headerAlign: 'center',
        cellAlign: 'center',
      },
      {
        key: 'role',
        title: 'Role',
        dataIndex: 'role',
        width: '10%',
        headerAlign: 'center',
        cellAlign: 'center',
      },
      {
        key: 'brand',
        title: 'Brand',
        dataIndex: 'brand',
        width: '10%',
        headerAlign: 'center',
        cellAlign: 'center',
      },
      {
        key: 'createdAt',
        title: 'Created At',
        dataIndex: 'createdAt',
        width: '10%',
        headerAlign: 'center',
        cellAlign: 'center',
      },
    ],
    [],
  );
};

// Hook to fetch users with pagination
export const useUsersData = (
  filter: string,
  page: number,
  limit: number,
  sortField: string,
  sortOrder: string,
) => {
  const [users, setUsers] = useState<User[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await fetchAllUsersAndLinks({
          filter,
          page,
          limit,
          sortField,
          sortOrder,
        });

        const usersWithTotals = await Promise.all(
          response.data.map(async (user: User) => {
            const linkResponse = await getUserLinks(user._id);
            user.links = linkResponse;
            const totalClicks = user.links.reduce(
              (sum, link) => sum + link.clickCount,
              0,
            );
            user.totalClicks = totalClicks;
            user.totalLinks = user.links.length;

            return user;
          }),
        );

        setUsers(usersWithTotals);
        setTotalUsers(response.pagination.totalUsers);
        setTotalPages(response.pagination.totalPages); // Get the total number of pages
        setLoading(false);
      } catch (err) {
        setError('Failed to load users');
        setLoading(false);
      }
    };

    fetchUsers();
  }, [filter, page, limit, sortField, sortOrder]);

  return { users, loading, error, totalPages, totalUsers };
};

// Hook to fetch links for a selected user
export const useLinksData = (userId: string | null) => {
  const [links, setLinks] = useState<Link[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!userId) return;
    const fetchLinks = async () => {
      setLoading(true);
      try {
        const response = await getUserLinks(userId);
        setLinks(response);
        setLoading(false);
      } catch (err) {
        setError('Failed to load links');
        setLoading(false);
      }
    };

    fetchLinks();
  }, [userId]);

  return { links, loading, error };
};
