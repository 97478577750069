export const ROUTES = {
  HOME: '/',
  SIGNIN: '/signin',
  SOCIAL_AUTH: '/sa',
  SIGNUP: '/signup',
  SIGNUP_INFLUENCER: '/signup-influencer',
  SIGNUP_BRAND: '/signup-brand',
  FORGET_PASSWORD: '/forget-password',
  TEST_DASHBOARD: '/test-dashboard',
  MY_LINKS: '/my-links',
  BULK_UPLOAD: '/bulk-upload',
  ANALYTICS: '/analytics',
  USER_MANAGEMENT: '/user-management',
  PROFILE: '/profile',
  WALLET: '/wallet',
  PLANS: '/plans',
  REDIRECT: '/redirect',
  TOKEN_REDIRECT: '/token-redirect',
  CHECKOUT: '/checkout/:label',
  ACTIVATE_ACCOUNT: '/activate/:userId/:token',
};
