import React from 'react';
import AnalyticsContainer from './Admin/AnalyticsContainer';
import UsersTable from './Admin/UsersTable';

const AdminDashboard: React.FC = () => {
  return (
    <div className="bg-white p-[24px]">
      <AnalyticsContainer />
      <UsersTable />
    </div>
  );
};

export default AdminDashboard;
