export const empty_dashboard = {
  title: 'Start Boosting Your Engagement Today!',
  description:
    'Create smart links that open directly in the native apps, driving higher engagement, boosting followers & subscribers, and better user experience.',
  blocks: [
    {
      title: 'Increase Engagement:',
      description:
        'Smart links open directly in the native app, reducing friction and increasing user interaction.',
    },
    {
      title: 'Easy to Use:',
      description: 'Create and manage your links with just a few clicks.',
    },
    {
      title: 'Track Performance:',
      description:
        'Monitor link performance with detailed analytics and insights.',
    },
    {
      title: 'Collaboration:',
      description: 'Collaborate with brand and get rewarding deal.',
    },
  ],
};

export const LinksData = [
  {
    logo: '/assets/youtubeLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Youtube',
    tags: '#Educational #Sciences',
    totalClicks: 200,
    percent: 18,
    indicateUp: true,
    id: 1,
  },
  {
    logo: '/assets/amazonLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Amazon',
    tags: '#Educational #Physic',
    totalClicks: 140,
    percent: 22,
    indicateUp: false,
    id: 2,
  },
  {
    logo: '/assets/spotifyLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Spotify',
    tags: '#Educational #Music',
    totalClicks: 140,
    percent: 75,
    indicateUp: true,
    id: 3,
  },
  {
    logo: '/assets/youtubeLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Youtube',
    tags: '#Educational #Sciences',
    totalClicks: 200,
    percent: 18,
    indicateUp: true,
    id: 4,
  },
  {
    logo: '/assets/amazonLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Amazon',
    tags: '#Educational #Physic',
    totalClicks: 140,
    percent: 22,
    indicateUp: false,
    id: 5,
  },
  {
    logo: '/assets/spotifyLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Spotify',
    tags: '#Educational #Music',
    totalClicks: 140,
    percent: 75,
    indicateUp: true,
    id: 6,
  },
  {
    logo: '/assets/youtubeLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Youtube',
    tags: '#Educational #Sciences',
    totalClicks: 200,
    percent: 18,
    indicateUp: true,
    id: 7,
  },
  {
    logo: '/assets/amazonLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Amazon',
    tags: '#Educational #Physic',
    totalClicks: 140,
    percent: 22,
    indicateUp: false,
    id: 8,
  },
  {
    logo: '/assets/spotifyLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Spotify',
    tags: '#Educational #Music',
    totalClicks: 140,
    percent: 7,
    indicateUp: true,
    id: 9,
  },
  {
    logo: '/assets/youtubeLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Youtube',
    tags: '#Educational #Sciences',
    totalClicks: 200,
    percent: 18,
    indicateUp: true,
    id: 10,
  },
  {
    logo: '/assets/amazonLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Amazon',
    tags: '#Educational #Medicne',
    totalClicks: 170,
    percent: 290,
    indicateUp: false,
    id: 11,
  },
  {
    logo: '/assets/spotifyLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Spotify',
    tags: '#Educational #Music',
    totalClicks: 10,
    percent: 2,
    indicateUp: false,
    id: 12,
  },
  {
    logo: '/assets/amazonLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Amazon',
    tags: '#Educational #Physic',
    totalClicks: 140,
    percent: 22,
    indicateUp: false,
    id: 13,
  },
  {
    logo: '/assets/spotifyLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Spotify',
    tags: '#Educational #Music',
    totalClicks: 140,
    percent: 75,
    indicateUp: true,
    id: 14,
  },
  {
    logo: '/assets/youtubeLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Youtube',
    tags: '#Educational #Sciences',
    totalClicks: 200,
    percent: 18,
    indicateUp: true,
    id: 15,
  },
  {
    logo: '/assets/amazonLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Amazon',
    tags: '#Educational #Physic',
    totalClicks: 140,
    percent: 22,
    indicateUp: false,
    id: 16,
  },
  {
    logo: '/assets/spotifyLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Spotify',
    tags: '#Educational #Music',
    totalClicks: 140,
    percent: 7,
    indicateUp: true,
    id: 17,
  },
  {
    logo: '/assets/youtubeLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Youtube',
    tags: '#Educational #Sciences',
    totalClicks: 200,
    percent: 18,
    indicateUp: true,
    id: 18,
  },
  {
    logo: '/assets/amazonLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Amazon',
    tags: '#Educational #Medicne',
    totalClicks: 170,
    percent: 290,
    indicateUp: false,
    id: 19,
  },
  {
    logo: '/assets/spotifyLogo.svg',
    link: 'https://linkfluencerstg.addwebprojects.com/nextdor',
    targetSite: 'Spotify',
    tags: '#Educational #Music',
    totalClicks: 10,
    percent: 2,
    indicateUp: false,
    id: 20,
  },
];

export const UserManagementData = [
  {
    id: 'EKG3345543',
    name: 'Cameron Williamson',
    email: 'harrystyls123@gmail.com',
    role: 'users',
    date: '15 Dec, 2024',
    invitedBy: 'cameron Williamson',
  },
  {
    id: 'EKG3345543',
    name: 'Cameron Williamson',
    email: 'harrystyls123@gmail.com',
    role: 'users',
    date: '15 Dec, 2024',
    invitedBy: 'cameron Williamson',
  },
  {
    id: 'EKG3345543',
    name: 'Cameron Williamson',
    email: 'harrystyls123@gmail.com',
    role: 'users',
    date: '15 Dec, 2024',
    invitedBy: 'cameron Williamson',
  },
  {
    id: 'EKG3345543',
    name: 'Cameron Williamson',
    email: 'harrystyls123@gmail.com',
    role: 'users',
    date: '15 Dec, 2024',
    invitedBy: 'cameron Williamson',
  },
];
export const walletData = [
  {
    id: 'EKG3345543',
    invoiceDate: '10, Dec, 2021',
    datePaid: '10, Dec, 2021',
    status: 'pending',
    plan: 'Started',
    paymentMode: '398********723 (Visa)',
    amount: '$129 USD',
  },
  {
    id: 'EKG3345544',
    invoiceDate: '11, Dec, 2021',
    datePaid: '11, Dec, 2021',
    status: 'success',
    plan: 'Grow',
    paymentMode: '398********724 (Mastercard)',
    amount: '$149 USD',
  },
  {
    id: 'EKG3345545',
    invoiceDate: '12, Dec, 2021',
    datePaid: '13, Dec, 2021',
    status: 'failed',
    plan: 'Scale',
    paymentMode: '398********725 (Visa)',
    amount: '$89 USD',
  },
  {
    id: 'EKG3345546',
    invoiceDate: '13, Dec, 2021',
    datePaid: '14, Dec, 2021',
    status: 'pending',
    plan: 'Started',
    paymentMode: '398********726 (PayPal)',
    amount: '$99 USD',
  },
  {
    id: 'EKG3345547',
    invoiceDate: '14, Dec, 2021',
    datePaid: '15, Dec, 2021',
    status: 'success',
    plan: 'Grow',
    paymentMode: '398********727 (Visa)',
    amount: '$199 USD',
  },
  {
    id: 'EKG3345548',
    invoiceDate: '15, Dec, 2021',
    datePaid: '16, Dec, 2021',
    status: 'failed',
    plan: 'Scale',
    paymentMode: '398********728 (Mastercard)',
    amount: '$59 USD',
  },
  {
    id: 'EKG3345549',
    invoiceDate: '16, Dec, 2021',
    datePaid: '17, Dec, 2021',
    status: 'success',
    plan: 'Scale',
    paymentMode: '398********729 (Visa)',
    amount: '$79 USD',
  },
  {
    id: 'EKG3345550',
    invoiceDate: '17, Dec, 2021',
    datePaid: '18, Dec, 2021',
    status: 'pending',
    plan: 'Scale',
    paymentMode: '398********730 (PayPal)',
    amount: '$139 USD',
  },
  {
    id: 'EKG3345551',
    invoiceDate: '18, Dec, 2021',
    datePaid: '19, Dec, 2021',
    plan: 'Scale',
    status: 'success',
    paymentMode: '398********731 (Visa)',
    amount: '$169 USD',
  },
  {
    id: 'EKG3345552',
    invoiceDate: '19, Dec, 2021',
    datePaid: '20, Dec, 2021',
    plan: 'Scale',
    status: 'failed',
    paymentMode: '398********732 (Mastercard)',
    amount: '$89 USD',
  },
  {
    id: 'EKG3345553',
    invoiceDate: '20, Dec, 2021',
    datePaid: '21, Dec, 2021',
    plan: 'Scale',
    status: 'pending',
    paymentMode: '398********733 (Visa)',
    amount: '$129 USD',
  },
  {
    id: 'EKG3345554',
    invoiceDate: '21, Dec, 2021',
    datePaid: '22, Dec, 2021',
    plan: 'Scale',
    status: 'success',
    paymentMode: '398********734 (PayPal)',
    amount: '$199 USD',
  },
  {
    id: 'EKG3345555',
    invoiceDate: '22, Dec, 2021',
    datePaid: '23, Dec, 2021',
    plan: 'Scale',
    status: 'failed',
    paymentMode: '398********735 (Visa)',
    amount: '$99 USD',
  },
  {
    id: 'EKG3345556',
    invoiceDate: '23, Dec, 2021',
    datePaid: '24, Dec, 2021',
    plan: 'Scale',
    status: 'success',
    paymentMode: '398********736 (Mastercard)',
    amount: '$149 USD',
  },
  {
    id: 'EKG3345557',
    invoiceDate: '24, Dec, 2021',
    datePaid: '25, Dec, 2021',
    plan: 'Scale',
    status: 'pending',
    paymentMode: '398********737 (Visa)',
    amount: '$119 USD',
  },
];
