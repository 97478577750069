import { useContext, useEffect, useMemo, useState } from 'react';
import Table from '../components/common/table';
import { DeleteIcon, SearchIcon } from '../svg';
import useDeviceDetect from '../helpers/screens';
import UserSquare from '../components/common/cards/userSquar';
import AddUserModal from '../components/userManagement/AddUserModal';
import { addUserToBrand, getBrandUsers } from '../services/userService';
import { toast } from 'react-toastify';
import { UserContext } from '../context/UserContext';
import { render } from '@testing-library/react';
import { capitalizeFirstLetter } from '../utils/caseUtils';
import { BrandMemberRole } from '../types/enums';

const UserManagement: React.FC = () => {
  const userContext = useContext(UserContext);
  const [isDelete, setIsDelete] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const { isMobile } = useDeviceDetect();

  if (!userContext) {
    throw new Error('useContext must be used within a UserProvider');
  }
  const { user } = userContext;

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const isAdmin = useMemo(
    () => user?.brandMemberRole === BrandMemberRole.Admin,
    [user?.brandMemberRole],
  );

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: '150px',
      headerAlign: 'left',
      cellAlign: 'left',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: '150px',
      headerAlign: 'left',
      cellAlign: 'left',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      width: '280px',
      headerAlign: 'left',
      cellAlign: 'left',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: '100px',
      headerAlign: 'left',
      cellAlign: 'left',
      render: (row: any) => {
        return capitalizeFirstLetter(row.role) || 'No Role';
      },
    },
    {
      title: 'Invitation Date',
      dataIndex: 'date',
      key: 'date',
      width: '170px',
      headerAlign: 'left',
      cellAlign: 'left',
    },
    {
      title: 'Invited By',
      dataIndex: 'invitedBy',
      key: 'invitedBy',
      width: '250px',
      headerAlign: 'left',
      cellAlign: 'left',
    },
  ];

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const fetchUsers = async () => {
    try {
      const data = await getBrandUsers(
        user?._id as string,
        user?.brand as string,
      );
      setUsers(data);
      setFilteredData(data);
    } catch (error) {
      toast.error('Failed to fetch user:' + error);
    }
  };

  const formatDataForTable = (data: any[]) => {
    return data.map((item) => ({
      id: item.userInfo._id,
      firstName: item.userInfo.firstName || '',
      lastName: item.userInfo.lastName || '',
      email: item.userInfo.email,
      role: item.brandInfo.role,
      date: new Date(item.userInfo.createdAt).toLocaleDateString(),
      invitedBy: item.addedByInfo.email,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUsers();
    };
    fetchData();
  }, [user]);

  useEffect(() => {
    const results = users.filter((item) =>
      Object.values(item).some((val) =>
        JSON.stringify(val).toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
    setFilteredData(results);
  }, [searchTerm, users]);

  const handleAddUser = async (invitedUser: any) => {
    try {
      if (user) {
        await addUserToBrand(
          user._id,
          user.brand as string,
          invitedUser.name,
          invitedUser.email,
          invitedUser.role,
        );
        toast.success('User added successfully');
        await fetchUsers();
      }
    } catch (error) {
      toast.error('Failed to add user');
    }
  };

  const handleSelectLink = (id: string) => {
    console.log(`Selected link ID: ${id}`);
  };

  return (
    <div>
      <AddUserModal
        isModalOpen={isModalOpen}
        handleModal={handleModal}
        onAddUser={handleAddUser}
      />
      <div className="flex flex-col p-[12px] sm:p-[24px] bg-[#F0F5FF]">
        <div className="flex flex-col xs:flex-row xs:justify-between items-start xs:items-center bg-[#F0F5FF]">
          <h1 className="text-2xl font-header">User Management</h1>
          {isAdmin && (
            <button
              onClick={handleModal}
              className="w-full xs:w-[150px] mt-2 md:mt-0 md:ml-2 ml-0 border-[1px] border-[#113E53] font-bold bg-[#113E53] rounded-full px-[20px] py-[8px] text-white font-header"
            >
              Add User
            </button>
          )}
        </div>
      </div>
      <div className="p-[12px] sm:p-[24px]">
        {!isDelete ? (
          <div className="flex flex-col md:flex-row md:items-center justify-between mb-2">
            <div className="border-b-2 flex items-center w-full md:w-[300px]">
              <SearchIcon className={'size-5 text-gray-400'} />
              <input
                type="text"
                placeholder="Search"
                name="search"
                id="search"
                onChange={handleSearch}
                value={searchTerm}
                className="border-none outline-none bg-transparent p-[10px]"
              />
            </div>
            <div className="flex items-center gap-2 justify-end mt-[8px] md:mt-0">
              <div className="p-2 rounded-full hover:bg-gray-100 duration-200">
                {isAdmin && (
                  <DeleteIcon
                    className={'size-5 cursor-pointer'}
                    onClick={() => setIsDelete(true)}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col-reverse md:flex-row justify-between items-between">
            {isDelete && isMobile ? (
              <div className="flex items-center gap-3 mb-2">
                <input type="checkbox" className="w-5 h-5" />
                <span className="font-bold font-header text-[#172B4D]">
                  Select All
                </span>
              </div>
            ) : (
              <div></div>
            )}
            <div className="flex justify-end gap-2 my-4">
              <button
                onClick={() => setIsDelete(false)}
                className="md:mt-0 md:ml-2 ml-0 border-[1px] w-[150px] border-[#113E53] font-bold rounded-full px-[20px] py-[8px] text-[#113E53] font-header"
              >
                Cancel
              </button>
              <button className="md:mt-0 md:ml-2 ml-0 border-[1px] w-[150px] border-[#113E53] font-bold bg-[#113E53] rounded-full px-[20px] py-[8px] text-white font-header">
                Delete
              </button>
              <button className="md:mt-0 md:ml-2 ml-0 border-[1px] w-[150px] border-[#113E53] font-bold bg-[#113E53] rounded-full px-[20px] py-[8px] text-white font-header">
                Block
              </button>
            </div>
          </div>
        )}
        {!isMobile ? (
          <Table
            columns={columns}
            dataSource={formatDataForTable(filteredData)}
            tableData={{ select: isDelete }}
            selectedData={selectedData} // Pass selectedData here
            setSelectedData={setSelectedData} // And pass setSelectedData
            handleSelectLink={handleSelectLink} // Pass the function here
          />
        ) : (
          formatDataForTable(filteredData).map((val, index) => (
            <div className="mb-4" key={index}>
              <UserSquare
                isDelete={isDelete}
                values={val}
                showDelete={isAdmin}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default UserManagement;
