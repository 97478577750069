import { createContext, useState, useEffect, ReactNode } from 'react';
import { fetchRoleInBrand, fetchUser } from '../services/userService';
import { IUser } from '../interfaces/User';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from '../components/common/Loading';
import { UserRole } from '../types/enums';

interface UserContextType {
  user: IUser | null;
  setUser: (user: IUser | null) => void;
  loading: boolean;
}

export const UserContext = createContext<UserContextType | undefined>(
  undefined,
);

const PUBLIC_ROUTES = [
  '/signin',
  '/signup',
  '/signup-influencer',
  '/signup-brand',
  '/forget-password',
  '/token-redirect',
  '/activate',
  '/activate/:userId/:token',
];

// Fonction pour vérifier si une route est publique, y compris les routes dynamiques
const isPublicRoute = (path: string): boolean => {
  return PUBLIC_ROUTES.some((route) => {
    if (route.includes(':')) {
      const routeRegex = new RegExp(
        '^' + route.replace(/:[^\s/]+/g, '([^/]+)') + '$',
      );
      return routeRegex.test(path);
    }
    return route === path;
  });
};

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const getUser = async () => {
      setLoading(true);
      try {
        const userData = await fetchUser();
        localStorage.getItem('accessToken');

        if (!userData && !isPublicRoute(location.pathname)) {
          navigate('/signin');
          return;
        }

        if (
          userData &&
          userData.brand &&
          userData.role === UserRole.BrandUser
        ) {
          const brandMemberRole = await fetchRoleInBrand(
            userData._id,
            userData.brand,
          );
          userData.brandMemberRole = brandMemberRole;
        }

        setUser(userData);
      } catch (err) {
        if (!isPublicRoute(location.pathname)) {
          navigate('/signin');
        }
      } finally {
        setLoading(false);
      }
    };

    getUser();
  }, [location.pathname, navigate]);

  if (loading) {
    return <Loading />;
  }

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};
