import React, { useContext, useEffect, useState } from 'react';
import ApexChart from '../common/charts/LineChart/DashboardChart';
import ColumnChart from '../common/charts/columnChart';
import { UserContext } from '../../context/UserContext';
import {
  TopCountries,
  TopPerformingPlatform,
} from '../../services/userService';
import { CountryClicks, ISource } from '../../types/interfaces';
import Loading from '../common/Loading';
import { fetchIcon } from '../../utils/iconUtils';
import WorldMap from '../common/charts/WorldMap';

const AnalyticsSection: React.FC = () => {
  const userContext = useContext(UserContext);
  const user = userContext?.user;

  const [topSources, setTopSources] = useState<ISource[]>([]);
  const [topCountries, setTopCountries] = useState<CountryClicks[]>([]);
  const [loadingSources, setLoadingSources] = useState<boolean>(true);
  const [loadingCountries, setLoadingCountries] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchTopSources = async () => {
    if (user) {
      try {
        setLoadingSources(true);
        const sources = await TopPerformingPlatform(user._id, 'year');
        const sortedSources = sources
          .sort((a: ISource, b: ISource) => b.count - a.count)
          .slice(0, 5);
        setTopSources(sortedSources);
      } catch (error) {
        setError('Error when fetching top performing sources');
      } finally {
        setLoadingSources(false);
      }
    }
  };

  const fetchTopCountries = async () => {
    if (user) {
      try {
        setLoadingCountries(true);
        const countries = await TopCountries(user._id, 'year');
        const topFiveCountries = countries
          .sort((a: any, b: any) => b.clicks - a.clicks)
          .slice(0, 5);

        setTopCountries(topFiveCountries);
      } catch (error) {
        setError('Erreur lors de la récupération des pays principaux');
        console.error(error);
      } finally {
        setLoadingCountries(false);
      }
    }
  };

  useEffect(() => {
    fetchTopCountries();
    fetchTopSources();
  }, [user]);

  if (error) {
    return <div>{error}</div>;
  }

  const totalCount = topSources?.reduce(
    (total, source) => total + source.count,
    0,
  );

  return (
    <div className="mt-12">
      <h1 className="font-semibold text-lg font-content">Analytics</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-4">
        {/* Top Sources */}
        <div className="border h-[300px] flex flex-col justify-between items-start px-[20px] py-[20px] rounded-2xl w-full">
          <span className="text-[#4C4C4C] font-content whitespace-nowrap">
            Top Sources
          </span>
          <div className="flex justify-around w-full h-full gap-4">
            {loadingSources ? (
              <Loading />
            ) : totalCount === 0 ? (
              <div className="flex items-center justify-center h-full w-full">
                <span className="text-gray-500 text-lg font-semibold">
                  No Data
                </span>
              </div>
            ) : (
              topSources.slice(0, 5).map((source) => {
                const percent = (source.count / totalCount) * 100;
                const displayPercent = percent < 1 ? 1 : Math.round(percent);

                return (
                  <div
                    key={source.label}
                    className="relative h-full flex-1 max-w-[60px]"
                  >
                    <ColumnChart
                      percent={displayPercent}
                      logo={fetchIcon(source.label.toLowerCase())}
                      altText={source.label}
                    />
                  </div>
                );
              })
            )}
          </div>
        </div>

        {/* Users Traffic by Region */}
        <div className="border h-[300px] pt-[24px] rounded-2xl relative">
          <span className="text-[#4C4C4C] font-content whitespace-nowrap pl-5">
            Users traffic by region
          </span>
          <div className="h-full flex flex-col justify-around mt-[5px]">
            {loadingCountries ? (
              <Loading />
            ) : (
              <WorldMap countries={topCountries} />
            )}
          </div>
        </div>

        {/* YouTube Earning Section */}
        <div className="flex border h-[300px] flex-col relative items-center p-[20px] rounded-2xl w-full">
          <div className="relative flex w-full items-center h-full justify-between">
            <div className="h-full w-full flex flex-col justify-center">
              <span className="text-[#4C4C4C] font-content whitespace-nowrap">
                YouTube Yearly Earning
              </span>
              <span className="text-primary font-bold">SOON!</span>
            </div>
          </div>

          <div className="w-full border-[1.5px] mt-2"></div>

          <div className="relative flex w-full items-center h-full justify-between">
            <div className="h-full w-full flex flex-col justify-center">
              <span className="text-[#4C4C4C] font-content whitespace-nowrap">
                YouTube This Month Earning
              </span>
              <span className="text-primary font-bold">SOON!</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsSection;
