import { useContext, useEffect, useState } from 'react';
import Brand from './Brand';
import User from './User';
import Influencer from './Influencer';
import { UserContext } from '../../context/UserContext';
import { UserRole } from '../../types/enums';

const Profile: React.FC = () => {
  const userContext = useContext(UserContext);
  const [display, setDisplay] = useState('brand');

  if (!userContext) {
    throw new Error('useContext must be used within a UserProvider');
  }
  const { user } = userContext;

  return (
    <div className="p-[12px] sm:p-[24px] bg-white">
      <h1 className="font-header text-[24px] text-[#172B4D]">Profile</h1>
      {user?.role !== UserRole.User && (
        <div className="my-[30px]">
          <ul className="flex items-center gap-4">
            <li
              className={`border-b-[3px] ${display === 'brand' ? 'border-[#252C32] font-[600]' : 'border-transparent'} text-[#252C32] cursor-pointer duration-300`}
              onClick={() => {
                setDisplay('brand');
              }}
            >
              Brand
            </li>
            <li
              className={`border-b-[3px] ${display === 'user' ? 'border-[#252C32] font-[600]' : 'border-transparent'} text-[#252C32] cursor-pointer duration-300`}
              onClick={() => {
                setDisplay('user');
              }}
            >
              User
            </li>
          </ul>
        </div>
      )}
      {user?.role === UserRole.BrandUser && display === 'brand' && <Brand />}
      {user?.role === UserRole.BrandUser && display === 'user' && <User />}
      {user?.role === UserRole.User && <Influencer />}
    </div>
  );
};

export default Profile;
