import { useContext, useState, useEffect } from 'react';
import PlanCard from '../../components/common/cards/Plan';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { subscribePlan } from '../../services/linkService';
import data from './plans.json';
import CardDetailsForm from './CardDetailsForm';
import CustomerDetailsForm from './CustomerDetailsForm';
import { toast } from 'react-toastify';

export const Checkout: React.FC = () => {
  const userContext = useContext(UserContext);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { label } = useParams();
  const [selectedPlan, setSelectedPlan] = useState(data[0]);

  if (!userContext) {
    throw new Error('useContext must be used within a UserProvider');
  }
  const { user } = userContext;

  useEffect(() => {
    setCustomerValues({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      companyName: '',
      postalCode: '',
      address: user?.address || '',
      city: user?.city || '',
      country: user?.country || '',
      phone: user?.mobileNumber || '',
      email: user?.email || '',
    });
  }, [user]);

  useEffect(() => {
    const plan = data.find(
      (plan) => plan.Label.toLowerCase() === label?.toLowerCase(),
    );
    if (plan) setSelectedPlan(plan);
  }, [label]);

  const [customerValues, setCustomerValues] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    postalCode: '',
    address: '',
    city: '',
    country: '',
    phone: '',
    email: '',
  });

  const [cardValues, setCardValues] = useState({
    nameOnCard: '',
    cardNumber: '',
    expiryDate: '',
    CVV: '',
  });

  const handleCustomerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCustomerValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'cardNumber') {
      const formattedValue = value
        .replace(/\D/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim()
        .slice(0, 19);
      setCardValues((prevValues) => ({
        ...prevValues,
        [name]: formattedValue,
      }));
    } else if (name === 'expiryDate') {
      const formattedValue = value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d{0,2})/, '$1/$2')
        .slice(0, 5);
      setCardValues((prevValues) => ({
        ...prevValues,
        [name]: formattedValue,
      }));
    } else if (name === 'CVV') {
      const formattedValue = value.replace(/\D/g, '').slice(0, 4);
      setCardValues((prevValues) => ({
        ...prevValues,
        [name]: formattedValue,
      }));
    } else {
      setCardValues((prevValues) => ({ ...prevValues, [name]: value }));
    }
  };

  const validateForm = () => {
    const { cardNumber, expiryDate, CVV } = cardValues;
    const { email, phone } = customerValues;
    const errors = [];

    if (!/^\d{4} \d{4} \d{4} \d{4}$/.test(cardNumber))
      errors.push('Invalid card number.');
    if (!/^\d{2}\/\d{2}$/.test(expiryDate))
      errors.push('Invalid expiry date format.');
    if (!/^\d{3,4}$/.test(CVV)) errors.push('Invalid CVV.');
    if (!/^\S+@\S+\.\S+$/.test(email)) errors.push('Invalid email format.');
    if (!/^\+?\d{10,15}$/.test(phone))
      errors.push('Invalid phone number format.');

    if (errors.length) {
      setError(errors.join(' '));
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    setLoading(true);
    setSuccess(null);
    setError(null);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    const amount = parseFloat(selectedPlan.price.replace('$', ''));
    const payload = {
      stripeToken: {
        amount: Math.floor(amount * 100),
        currency: 'usd',
        type: 'card',
        card: {
          number: cardValues.cardNumber.replace(/\s/g, ''),
          exp_month: cardValues.expiryDate.split('/')[0],
          exp_year: `20${cardValues.expiryDate.split('/')[1]}`,
          cvc: cardValues.CVV,
        },
        name: cardValues.nameOnCard,
        email: customerValues.email,
        metadata: {
          address: customerValues.address,
          city: customerValues.city,
          postcode: customerValues.postalCode,
          country: customerValues.country,
          phone: customerValues.phone,
        },
      },
      plan: label?.toLowerCase(),
    };

    try {
      if (user && user._id) {
        const response = await subscribePlan(user._id, payload);
        if (response) {
          setSuccess('Subscription successful!');
          toast.success('Subscribed successfully to plan ' + label);
          setTimeout(() => (window.location.href = '/'), 1000);
        } else {
          setError('Subscription failed.');
          toast.error('Failed to subscribe to plan ' + label);
        }
      }
    } catch (err) {
      toast.error('Failed to subscribe to plan ' + label);
      setError('Failed to upload data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="p-[24px]">
        <h1 className="text-2xl font-header">Checkout</h1>
      </div>
      <div className="bg-white p-[24px] flex flex-col md:flex-row">
        <div className="w-full md:w-2/3 pr-4">
          <CustomerDetailsForm
            customerValues={customerValues}
            handleCustomerChange={handleCustomerChange}
          />

          <CardDetailsForm
            cardValues={cardValues}
            handleCardChange={handleCardChange}
          />
          <div className="space-y-4 mb-8">
            {success && <p className="text-green-800">{success}</p>}
            {error && <p className="text-red-800">{error}</p>}
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <PlanCard
            key={label}
            loading={loading}
            data={selectedPlan}
            onClick={handleSubmit}
            isLink={false}
          />
        </div>
      </div>
    </div>
  );
};
