import { UserContext } from '../../context/UserContext';
import {
  UserPassword,
  UserProfile,
  UserUpdate,
} from '../../services/userService';
import { useContext, useEffect, useState } from 'react';
import { formatISODateToDMY } from '../../utils/DateIosToDMY';
import { getCountriesData } from '../../utils/countryUtils';
import { toast } from 'react-toastify';
import UpdatePasswordForm from '../../components/profile/UpdatePasswordForm';
import UserProfileForm from '../../components/profile/UserProfileForm';

interface UserProfileValues {
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  gender: string;
  country: string;
  city: string;
  mobileNumber: string;
  countryCode: string;
  address: string;
  postalCode: string;
  birthDate: string;
}

interface UserPasswordValues {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const Influencer: React.FC = () => {
  const [values, setValues] = useState<UserProfileValues>({
    firstName: '',
    lastName: '',
    email: '',
    status: '',
    gender: '',
    country: '',
    city: '',
    mobileNumber: '',
    countryCode: '',
    address: '',
    postalCode: '',
    birthDate: '',
  });

  const [passValues, setPassValues] = useState<UserPasswordValues>({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const [countries, setCountries] = useState<any[]>([]);
  const [editPasswordMode, setEditPasswordMode] = useState(false);

  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error('useContext must be used within a UserProvider');
  }
  const { user } = userContext;

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { value, name } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handlePassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setPassValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleEdit = async () => {
    if (!values.email) {
      toast.error('Email is required');
      return;
    }

    if (user && user._id) {
      try {
        const updatedUser = await UserUpdate(user._id, {
          ...values,
        });
        toast.success('Profile updated successfully');
        setValues({
          ...updatedUser,
          birthDate: formatISODateToDMY(updatedUser?.birthDate),
        });
      } catch (error) {
        toast.error('Failed to update profile');
      }
    }
  };

  const isPasswordMatch =
    passValues.newPassword === passValues.confirmNewPassword &&
    passValues.newPassword.length > 0 &&
    passValues.oldPassword.length > 0;

  const handleUpdatePassword = async () => {
    if (isPasswordMatch) {
      if (user && user._id) {
        try {
          await UserPassword(user._id, {
            oldPassword: passValues.oldPassword,
            newPassword: passValues.newPassword,
          });
          toast.success('Password updated successfully');
        } catch (error) {
          toast.error('Failed to update password');
        }
      }
    } else {
      toast.error('New passwords do not match');
    }
  };

  const getUserProfile = async () => {
    if (user && user._id) {
      const userData = await UserProfile(user._id);

      setValues({
        ...userData,
        birthDate: formatISODateToDMY(userData?.birthDate),
      });
    }
  };

  useEffect(() => {
    setCountries(getCountriesData());
    getUserProfile();
  }, [user]);

  return (
    <div>
      <div className="gap-4 flex flex-col md:flex-row relative mt-8">
        <UserProfileForm
          user={user}
          values={values}
          handleChange={handleChange}
          countries={countries}
        />
      </div>
      <div className="flex mt-6 justify-end gap-4">
        <button
          className="md:mt-0 md:ml-2 ml-0 border-[1px] w-[120px] font-bold bg-[#F1F5F9] rounded-full px-[20px] py-[8px] text-[#113E53] font-header"
          onClick={getUserProfile}
        >
          Cancel
        </button>
        <button
          onClick={handleEdit}
          className="md:mt-0 md:ml-2 ml-0 border-[1px] w-[120px] border-[#113E53] font-bold bg-[#113E53] rounded-full px-[20px] py-[8px] text-white font-header"
        >
          Save
        </button>
      </div>

      <div className="flex flex-col mt-6 gap-4">
        <h1 className="text-4 font-[600] text-[#172B4D]">Password Set-up</h1>
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <UpdatePasswordForm
            passValues={passValues}
            handlePassChange={handlePassChange}
            editPasswordMode={editPasswordMode}
          />
        </div>
      </div>
      <div className="flex justify-end gap-4">
        {!editPasswordMode ? (
          <button
            onClick={() => setEditPasswordMode(true)}
            className="md:mt-0 md:ml-2 ml-0 border-[1px] w-[120px] border-[#113E53] font-bold bg-[#113E53] rounded-full px-[20px] py-[8px] text-white font-header"
          >
            Edit
          </button>
        ) : (
          <>
            <button
              onClick={() => setEditPasswordMode(false)}
              className="md:mt-0 md:ml-2 ml-0 border-[1px] w-[120px] font-bold bg-[#F1F5F9] rounded-full px-[20px] py-[8px] text-[#113E53] font-header"
            >
              Cancel
            </button>
            <button
              onClick={handleUpdatePassword}
              disabled={!isPasswordMatch}
              className={`md:mt-0 md:ml-2 ml-0 border-[1px] w-[120px] font-bold ${
                isPasswordMatch
                  ? 'bg-[#113E53] text-white'
                  : 'bg-gray-300 text-gray-500'
              } rounded-full px-[20px] py-[8px]`}
            >
              Save
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Influencer;
