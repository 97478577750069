import React from 'react';

interface CardDetailsFormProps {
  cardValues: any;
  handleCardChange: (values: any) => void;
}

const CardDetailsForm: React.FC<CardDetailsFormProps> = ({
  cardValues,
  handleCardChange,
}) => {
  return (
    <>
      <h1 className="font-semibold text-lg font-content mb-4 mt-8">
        Credit/Debit Card Details
      </h1>
      <div className="space-y-4 mb-8">
        {/* Flex container for responsive layout */}
        <div className="flex flex-col md:flex-row gap-4">
          <input
            type="text"
            placeholder="Name on Card"
            name="nameOnCard"
            value={cardValues.nameOnCard}
            onChange={handleCardChange}
            className="w-full md:w-1/2 rounded-3xl p-2 outline-none border"
          />
          <input
            type="text"
            placeholder="Card Number"
            name="cardNumber"
            value={cardValues.cardNumber}
            onChange={handleCardChange}
            maxLength={19}
            className="w-full md:w-1/2 rounded-3xl p-2 outline-none border"
          />
        </div>

        <div className="flex flex-col md:flex-row gap-4">
          <input
            type="text"
            placeholder="Expiry Date (MM/YY)"
            name="expiryDate"
            value={cardValues.expiryDate}
            onChange={handleCardChange}
            maxLength={5}
            className="w-full md:w-1/4 rounded-3xl p-2 outline-none border"
          />
          <input
            type="text"
            placeholder="CVV"
            name="CVV"
            value={cardValues.CVV}
            onChange={handleCardChange}
            maxLength={4}
            className="w-full md:w-1/5 rounded-3xl p-2 outline-none border"
          />
        </div>
      </div>
    </>
  );
};

export default CardDetailsForm;
