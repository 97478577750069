import React from 'react';

interface ColumnProps {
  percent: number;
  logo: string;
  altText?: string;
}

const ColumnChart: React.FC<ColumnProps> = ({
  percent,
  logo,
  altText = 'Social Logo',
}) => {
  const heightPercentage = `${percent}%`;
  const formattedPercent = percent.toFixed(0);

  return (
    <div
      className="h-full flex flex-col justify-end items-center text-center"
      aria-label={`${formattedPercent}%`}
    >
      <span className="text-gray-500 text-sm font-medium mb-1">
        {formattedPercent}%
      </span>
      <div
        className="w-[20px] rounded-2xl bg-[#E3CCF4] transition-all duration-300 ease-out"
        style={{ height: heightPercentage }}
      ></div>
      <img
        src={logo}
        alt={altText}
        className="mt-2 h-[24px] w-[24px] object-contain"
        title={altText}
      />
    </div>
  );
};

export default ColumnChart;
