import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { useEffect, useState } from 'react';
import { getAdminAnalytics } from '../../../services/userService';

interface Slide {
  label: string;
  value?: string | null;
}

const StatsSwiper: React.FC = () => {
  const [totalUsers, setTotalUsers] = useState<string | null>(null);
  const [totalLinks, setTotalLinks] = useState<string | null>(null);
  const [totalClicks, setTotalClicks] = useState<string | null>(null);

  useEffect(() => {
    const getAnalytics = async () => {
      const analytics = await getAdminAnalytics();
      setTotalUsers(analytics?.users);
      setTotalLinks(analytics?.links);
      setTotalClicks(analytics?.clicks);
    };
    getAnalytics();
  }, []);
  const slidesData: Slide[] = [
    {
      label: 'Total Users',
      value: totalUsers,
    },
    {
      label: 'Total Links',
      value: totalLinks,
    },
    { label: 'Total Clicks', value: totalClicks },
  ];

  return (
    <Swiper
      slidesPerView={3}
      spaceBetween={12}
      freeMode={false}
      breakpoints={{
        200: { slidesPerView: 1 },
        320: { slidesPerView: 1.6 },
        500: { slidesPerView: 2.1 },
        690: { slidesPerView: 2.7 },
        1080: { slidesPerView: 3.2 },
        1300: { slidesPerView: 3.2 },
        1400: { slidesPerView: 3.2 },
      }}
      modules={[FreeMode, Pagination]}
      className="mySwiper cursor-pointer"
      style={{ maxWidth: '100%' }}
    >
      {slidesData.map((slide, index) => (
        <SwiperSlide
          key={index}
          className="border p-[24px] rounded-3xl bg-white max-h-[100px] h-[80px] max-w-full"
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          <span className="text-xs font-content text-[#9B919D]">
            {slide.label || '-'}
          </span>
          <div className="flex items-center gap-2">
            {slide.value ? (
              <span className="text-[#292828] text-[24px] font-[500] whitespace-nowrap">
                {slide.value || '-'}
              </span>
            ) : (
              <span className="text-[#292828] text-[24px] font-[500] whitespace-nowrap">
                No Data
              </span>
            )}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default StatsSwiper;
